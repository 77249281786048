import {Injectable} from '@angular/core';
import {ApiService} from '../api/api.service';
import {Router} from '@angular/router';

@Injectable({
  providedIn: 'root'
})
export class StatusService {
  STATUS_DOWN = 'down';
  STATUS_UP = 'up';

  constructor(private apiService: ApiService,
              private router: Router
  ) {
  }

  checkStatus(): Promise<any> {
    return (this.apiService.get('/status/maintenance') as Promise<any>);
  }
}
