import {Injectable} from '@angular/core';
import {Adapter} from '../../core/adapter';
import {Language} from './language';

@Injectable({
  providedIn: 'root'
})

export class LanguageAdapter implements Adapter<Language> {

  constructor() {

  }

  adapt(item: any): Language {
    return new Language(
      item.id,
      item.name,
      item.iso3
    );
  }
}
