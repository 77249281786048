import {Component, Input, OnInit} from '@angular/core';

@Component({
  selector: 'basic-page-background',
  templateUrl: './page-background.component.html',
  styleUrls: ['./page-background.component.scss']
})
export class BasicBackgroundComponent {

  @Input() src: string;

  constructor() {
  }
}
