import {Tag} from '../tag/tag';
import {Media} from '../media/media';
import {Moment} from 'moment';

export class Video {

  constructor(
    public id: string,
    public title: string,
    public subtitle: string,
    public tags: Tag[],
    public url: string,
    public onLive: boolean,
    public thumbnail: Media,
    public ads: any,
    public interruptedAt: number,
    public createdAt: Moment,
    public updatedAt: Moment
  ) {
  }
}
