import {Component, EventEmitter, Input, Output, ViewEncapsulation} from '@angular/core';
import {Video} from '../../../../model/video/video';
import {PlatformService} from '../../../services/platform/platform.service';

@Component({
  selector: 'basic-video-grid',
  templateUrl: './video-grid.component.html',
  styleUrls: ['./video-grid.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class BasicVideoGridComponent {
  @Input('videos') videos: Video[];
  @Input('autofocusOnFirst') autofocusOnFirst = false;
  @Input('loading') loading = false;
  @Input('loadingPosition') loadingPosition = 'center';
  @Output() onLastRowFocus = new EventEmitter();
  private videosPerRow = 3;

  constructor(
    private platformService: PlatformService
  ) {

  }

  handleOnFocus(video) {
    const index = this.videos.indexOf(video);
    if (index >= this.videos.length - this.videosPerRow) {
      this.onLastRowFocus.emit();
    }
  }
}
