import {Component, OnInit} from '@angular/core';
import {HelpersService} from '../../../services/helpers/helpers.service';
import {SpatialNavigationService} from '../../../services/spatial-navigation/spatial-navigation.service';

@Component({
  selector: 'layout-blank',
  templateUrl: './blank.component.html',
  styleUrls: ['./blank.component.scss']
})
export class LayoutBlankComponent implements OnInit {

  constructor(
    private helpersService: HelpersService,
    private spatialNavigationService: SpatialNavigationService
  ) {
  }

  ngOnInit() {
  }

  onActivate(event) {
    this.helpersService.scrollToTop();
    setTimeout(() => {
      this.spatialNavigationService.focusFirstElement();
    });
  }
}
