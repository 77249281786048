import {Component, Input, OnInit, ViewEncapsulation} from '@angular/core';
import {DomSanitizer} from '@angular/platform-browser';

@Component({
  selector: 'basic-loading',
  templateUrl: './loading.component.html'
})
export class BasicLoadingComponent implements OnInit {
  @Input('size') size = 'default';
  @Input('centered') centered = false;
  @Input('text') text = null;

  constructor(private domSanitizer: DomSanitizer) {
  }

  ngOnInit() {
  }

  getText() {
    return this.domSanitizer.bypassSecurityTrustHtml(this.text);
  }

}
