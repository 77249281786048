import {Component, Input, OnInit} from '@angular/core';
import {Video} from '../../../../../../model/video/video';
import {VideoService} from '../../../../../../model/video/video.service';
import {SearchService} from '../../../../../services/search/search.service';

@Component({
  selector: 'page-search-desktop',
  templateUrl: './search-desktop.component.html',
  styleUrls: ['./search-desktop.component.scss']
})
export class PageSearchDesktopComponent implements OnInit {
  videos: Video[] = [];
  searching = false;
  timer: number = null;
  loadingVideos = false;
  timeout = 1000;
  searchParams = {
    query: '',
    page: 1,
    limit: 12
  };
  existsMoreVideosInCurrentSearch: boolean;
  offsetToScroll = 200;

  constructor(private videoService: VideoService, private searchService: SearchService) {
    this.searchService.onSearch.subscribe((query: string) => {

      this.searchParams.query = query;
      this.searchParams.page = 1;
      this.existsMoreVideosInCurrentSearch = false;
      this.videos = [];

      if (this.timer) {
        clearTimeout(this.timer);
      }

      this.searching = true;
      this.timer = setTimeout(async () => {
        this.videos = await this.videoService.search(this.searchParams);
        if (this.videos.length == this.searchParams.limit) {
          this.existsMoreVideosInCurrentSearch = true;
        }
        this.searching = false;
      }, this.timeout);
    });
  }

  async loadMoreResults() {

    if (!this.existsMoreVideosInCurrentSearch) {
      return;
    }

    this.searchParams.page++;
    this.loadingVideos = true;
    this.videoService.search(this.searchParams)
      .then((videos: Video[]) => {
        this.loadingVideos = false;
        this.videos = [...this.videos, ...videos];
        if (videos.length < this.searchParams.limit) {
          this.existsMoreVideosInCurrentSearch = true;
        }
      });
  }

  ngOnInit() {
    document.addEventListener('scroll', ev => {
      if ((window.innerHeight + window.scrollY + this.offsetToScroll) >= document.body.offsetHeight) {
        this.loadMoreResults();
      }
    });
  }

}
