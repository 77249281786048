import {Component, OnInit, ViewEncapsulation} from '@angular/core';

@Component({
  selector: 'basic-the-footer',
  templateUrl: './the-footer.component.html',
  styleUrls: ['./the-footer.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class BasicTheFooterComponent implements OnInit {

  constructor() { }

  ngOnInit() {
  }

}
