import {Component, OnInit} from '@angular/core';
import {FormBuilder, FormGroup, Validators} from '@angular/forms';
import {ActivatedRoute} from '@angular/router';
import {ApiService} from '../../../services/api/api.service';
import {PasswordValidator} from '../../../../helpers/validators';

@Component({
  selector: 'page-reset-password',
  templateUrl: './reset-password.component.html',
  styleUrls: ['./reset-password.component.scss']
})

export class PageResetPasswordComponent implements OnInit {

  form: FormGroup;
  loading = false;
  submitted = false;
  error: string;
  done: boolean;

  constructor(
    private formBuilder: FormBuilder,
    private route: ActivatedRoute,
    private apiService: ApiService,
    private activatedRoute: ActivatedRoute
  ) {

  }

  ngOnInit() {

    this.form = this.formBuilder.group({
        email: ['', [Validators.required, Validators.email]],
        reset_token: ['', [Validators.required]],
        password: ['', [Validators.required]],
        password_confirmation: ['', [Validators.required]],
      },
      {
        validator: PasswordValidator.MatchPassword // your validation method
      });

    this.activatedRoute.queryParams.subscribe(params => {
      this.f['email'].setValue(params['email']);
    });
  }

  get f() {
    return this.form.controls;
  }

  onSubmit() {

    this.submitted = true;

    if (this.form.invalid) {
      return;
    }

    this.loading = true;

    this.apiService.post('/passwords/reset',
      {
        email: this.f.email.value,
        reset_token: this.f.reset_token.value,
        password: this.f.password.value,
        password_confirmation: this.f.password_confirmation.value
      })
      .then(data => {
        this.done = true;
      })
      .catch(response => {
        this.error = response.error.data.error;
        this.loading = false;
      });
  }
}
