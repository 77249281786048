import {Component, Input, OnInit, ViewEncapsulation, AfterViewInit, ElementRef} from '@angular/core';
import {HelpersService} from '../../../services/helpers/helpers.service';
import {PlatformService} from '../../../services/platform/platform.service';
import jQuery from 'jquery';
import {AuthService} from '../../../services/auth/auth.service';
import {CategoryService} from '../../../../model/category/category.service';
import {Category} from '../../../../model/category/category';
import {SpatialNavigationService} from '../../../services/spatial-navigation/spatial-navigation.service';

@Component({
  selector: 'basic-header-desktop',
  templateUrl: './header-desktop.component.html',
  styleUrls: ['./header-desktop.component.scss'],
  encapsulation: ViewEncapsulation.None
})

export class BasicHeaderDesktopComponent implements OnInit, AfterViewInit {

  @Input('show-nav-bar') showNavBar = true;

  isSticky: boolean = false;

  categories: Category[];

  constructor(
    private helpersService: HelpersService,
    public platformService: PlatformService,
    private element: ElementRef,
    private authService: AuthService,
    private categoryService: CategoryService,
    private spatialNavigationService: SpatialNavigationService
  ) {

  }

  ngOnInit() {

    window.addEventListener('scroll', () => this.stickyHandler());
    this.stickyHandler();

    this.fetchCategories();
  }

  getUser() {

    return this.authService.getUser();
  }

  stickyHandler() {

    const scrollTop = this.helpersService.getDocumentScrollTop();
    this.isSticky = scrollTop >= 100;
  }

  ngAfterViewInit() {

    jQuery(this.element.nativeElement).on('focus', '*', () => {
      this.helpersService.scrollToTop();
    });

    this.spatialNavigationService.focusFirstElement('.HeaderDesktop-menu');
  }

  fetchCategories() {

    this.categoryService.getRootCategories().then(categories => this.categories = categories);
  }
}
