import {AbstractControl} from '@angular/forms';

export class PasswordValidator {

  static MatchPassword(AC: AbstractControl, passwordFieldName?: string, passwordConfirmationFieldName?: string) {
    let password = AC.get(passwordFieldName || 'password').value;
    let passwordConfirmation = AC.get(passwordConfirmationFieldName || 'password_confirmation').value;
    if (password != passwordConfirmation) {
      AC.get(passwordConfirmationFieldName || 'password_confirmation').setErrors({match_password: true});
    } else {
      return null;
    }
  }
}
