import {Injectable} from '@angular/core';
import environment from '../../../environments/environment';

@Injectable({
  providedIn: 'root'
})
export class PlatformService {

  constructor() {
  }

  isSmartTV() {
    return environment.smartTV || localStorage.getItem('platform') === 'smart-tv';
  }

  isDesktop() {
    return !this.isSmartTV();
  }

  setSmartTV() {
    localStorage.setItem('platform', 'smart-tv');
  }

  setDesktop() {
    localStorage.setItem('platform', 'desktop');
  }
}
