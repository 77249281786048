import {Component, ViewEncapsulation} from '@angular/core';
import {PlatformService} from '../../../services/platform/platform.service';

@Component({
  selector: 'page-search',
  templateUrl: './search.component.html',
  styleUrls: ['./search.component.scss'],
  encapsulation: ViewEncapsulation.None
})

export class PageSearchComponent {

  constructor(public platformService: PlatformService) {

  }
}
