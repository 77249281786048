import {Component, Input, OnInit} from '@angular/core';
import {Video} from '../../../../model/video/video';

@Component({
  selector: 'page-videos-grid',
  templateUrl: './videos-grid.component.html',
  styleUrls: ['./videos-grid.component.scss']
})
export class PageVideosGridComponent implements OnInit {

  @Input() title: string;
  @Input() videos: Video[];
  @Input() loading: string;

  constructor() { }

  ngOnInit() {
  }

}
