import {Component, Input, OnInit, ViewChild, ViewEncapsulation} from '@angular/core';
import {SlickCarouselComponent} from 'ngx-slick-carousel';
import {Playlist} from '../../../../../../model/playlist/playlist';

@Component({
  selector: 'basic-playlist-carousel-desktop',
  templateUrl: './playlist-carousel-desktop.component.html',
  styleUrls: ['./playlist-carousel-desktop.component.scss']
})
export class BasicPlaylistCarouselDesktopComponent implements OnInit {
  @ViewChild('slickCarousel') slickCarousel: SlickCarouselComponent;
  @Input() playlist: Playlist;

  mobileBreakpoint = 767;
  isMobile = window.innerWidth < this.mobileBreakpoint;

  showAllHidden = this.isMobile;

  slideConfig = {
    slidesToShow: 4,
    slidesToScroll: 4,
    arrows: true,
    infinite: false,
    swipe: false,
    prevArrow: '<div class="PlaylistCarousel-arrow PlaylistCarousel-arrow--left"><i class="fas fa-chevron-left"></i></div>',
    nextArrow: '<div class="PlaylistCarousel-arrow PlaylistCarousel-arrow--right"><i class="fas fa-chevron-right"></i></div>',
    responsive: [
      {
        breakpoint: this.mobileBreakpoint,
        settings: {
          slidesToShow: 1.5,
          slidesToScroll: 1,
          swipe: true,
          arrows: false
        }
      }
    ]
  };

  constructor() {
  }

  ngOnInit() {
  }

  slickBeforeChange(e) {

    if (this.isMobile) {
      if (e.nextSlide !== 0) {
        this.showAllHidden = false;
      }
    } else {
      this.showAllHidden = e.nextSlide !== 0;
    }


  }

}
