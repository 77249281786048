import {Injectable} from '@angular/core';
import {ApiService} from '../../app/services/api/api.service';
import {BannerAdapter} from './banner.adapter';
import {Banner} from './banner';

@Injectable({
  providedIn: 'root'
})

export class BannerService {

  constructor(
    private apiService: ApiService,
    private adapter: BannerAdapter
  ) {

  }

  list(): Promise<Banner[]> {
    return new Promise((resolve, reject) => {
      this.apiService.get('/banners')

        .then((response) => {

          let items: Banner[] = [];
          response.data.banners.forEach(item => {
            let itemAdapted = this.adapter.adapt(item);
            items.push(itemAdapted);
          });

          resolve(items);
        })

        .catch((response) => {
          reject(response);
        });
    });
  }

  last(): Promise<Banner> {
    return new Promise<Banner|any>((resolve, reject) => {

      this.list().then(banners => {
        resolve(banners[0]);
      })
        .catch((response) => {
          reject(response);
        });
    });
  }

}
