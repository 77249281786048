import {Component, Input, ViewChild, ViewEncapsulation} from '@angular/core';
import {SlickCarouselComponent} from 'ngx-slick-carousel';
import {Banner} from '../../../../model/banner/banner';

@Component({
  selector: 'basic-banners-carousel',
  templateUrl: './banners-carousel.component.html',
  styleUrls: ['./banners-carousel.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class BasicBannersCarouselComponent  {

  @ViewChild('slickCarousel') slickCarousel: SlickCarouselComponent;
  @Input() banners: Banner[];

  mobileBreakpoint = 767;
  isMobile = window.innerWidth < this.mobileBreakpoint;

  slideConfig = {
    slidesToShow: 1,
    slidesToScroll: 1,
    autoplay: true,
    autoplaySpeed: 5000,
    arrows: true,
    dots: true,
    infinite: true,
    // fade: true,
    speed: 500,
    swipe: false,
    pauseOnFocus: true,
    pauseOnHover: false,
    accessibility: false,
    responsive: [
      {
        breakpoint: this.mobileBreakpoint,
        settings: {
          swipe: true,
          arrows: false
        }
      }
    ]
  };

  constructor() { }
}
