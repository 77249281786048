import {Component, OnInit, ViewChild, ViewEncapsulation} from '@angular/core';
import {SpatialNavigationService} from '../../../services/spatial-navigation/spatial-navigation.service';
import {Router} from '@angular/router';
import {AuthService} from '../../../services/auth/auth.service';
import {User} from '../../../../model/user/user';
import {UserService} from '../../../../model/user/user.service';

@Component({
  selector: 'page-change-profile-image',
  templateUrl: './change-profile-image.component.html',
  styleUrls: ['./change-profile-image.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class PageChangeProfileImageComponent implements OnInit {

  selectedImage: string;
  lastSelectedImage: string;

  imagePath = 'assets/img/profile/';
  imageList = [
    'profile_001.png',
    'profile_002.png',
    'profile_003.png',
    'profile_004.png',
    'profile_005.png',
    'profile_006.png',
  ];
  user: User;
  loading = false;

  @ViewChild('confirmButton') confirmButton;

  constructor(
    private spatialNavigationService: SpatialNavigationService,
    private router: Router,
    private authService: AuthService,
    private userService: UserService
  ) {
  }

  ngOnInit() {

    this.spatialNavigationService.init();
    this.spatialNavigationService.add('.ChangeProfileImagePage-preview');
    this.user = this.authService.getUser();
  }

  requestForSelectedImage(selectedImage) {

    this.selectedImage = selectedImage;
    this.lastSelectedImage = this.selectedImage;
  }

  cancelImageChange() {
    this.selectedImage = null;
  }

  confirmImageChange() {

    this.loading = true;

    this.userService.updateImage(this.selectedImage)

      .then(data => {
        this.loading = false;
        this.router.navigate(['/profile']);
      })

      .catch(response => {
        console.log('err update image', response.error.data.error);
      });
  }
}
