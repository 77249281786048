import {Directive, ElementRef} from '@angular/core';
import {SpatialNavigationService} from '../../services/spatial-navigation/spatial-navigation.service';

@Directive({
  selector: '[focusable]'
})
export class FocusableDirective {

  constructor(el: ElementRef, spatialNavigationService: SpatialNavigationService) {
    spatialNavigationService.add(el.nativeElement);
  }

}
