export class Category {

  constructor(
    public id: string,
    public name: string,
    public slug: string,
    public parentCategory: Category,
    public childrenCategories: Category[]
  ) {
  }

  hasChildrenCategories() {

    if (!this.childrenCategories || this.childrenCategories.length == 0) {
      return false;
    }

    return true;
  }

  hasGrandchildrenCategories() {

    if (!this.childrenCategories) {
      return false;
    }

    let nGrandChildren = 0;
    this.childrenCategories.forEach(subcategory => {
      if (subcategory.childrenCategories && subcategory.childrenCategories.length > 0) {
        nGrandChildren++;
      }
    });

    if (nGrandChildren) {
      return true;
    }

    return false;
  }
}
