import {Component, Input, ViewEncapsulation} from '@angular/core';
import {Category} from '../../../../model/category/category';
import {PlatformService} from '../../../services/platform/platform.service';

@Component({
  selector: 'basic-category-carousel',
  templateUrl: './category-carousel.component.html',
  styleUrls: ['./category-carousel.component.scss'],
  encapsulation: ViewEncapsulation.None
})

export class BasicCategoryCarouselComponent{

  @Input() category: Category;

  platformService: PlatformService;

  constructor(platformService: PlatformService) {
    this.platformService = platformService;
  }
}
