import {NgModule} from '@angular/core';
import {Routes, RouterModule} from '@angular/router';

// Layouts
import {LayoutMainComponent} from './components/layouts/main/main.component';
import {LayoutBlankComponent} from './components/layouts/blank/blank.component';
import {LayoutFullscreenComponent} from './components/layouts/fullscreen/fullscreen.component';

// Pages
import {PageLoginComponent} from './components/pages/login/login.component';
import {PageRecoveryPasswordComponent} from './components/pages/recovery-password/recovery-password.component';
import {PageResetPasswordComponent} from './components/pages/reset-password/reset-password.component';
import {PageRegistrationComponent} from './components/pages/registration/registration.component';
import {PageProfileComponent} from './components/pages/profile/profile.component';

import {PageHomeComponent} from './components/pages/home/home.component';
import {PageOnLiveComponent} from './components/pages/on-live/on-live.component';
import {PageMyListComponent} from './components/pages/my-list/my-list.component';
import {PageSearchComponent} from './components/pages/search/search.component';

import {PageVideoComponent} from './components/pages/video/video.component';

import {PageCategoryComponent} from './components/pages/category/category.component';
import {BasicSplashScreenComponent} from './components/basic/splash-screen/splash-screen.component';

import {PageChangeProfileImageComponent} from './components/pages/change-profile-image/change-profile-image.component';
import {PageChangeProfilePasswordComponent} from './components/pages/change-profile-password/change-profile-password.component';
import {PageChangeProfileLanguageComponent} from './components/pages/change-profile-language/change-profile-language.component';
import {PageUsageConditionsComponent} from './components/pages/usage-conditions/usage-conditions.component';

// Services
import {AuthGuard} from './services/auth/auth.guard';
import {PageMaintenanceComponent} from './components/pages/maintenance/maintenance.component';
import {StatusGuard} from './guards/status/status.guard';
import {HomeGuard} from './guards/home/home.guard';

const routes: Routes = [
  {
    path: '',
    component: LayoutMainComponent,
    canActivate: [AuthGuard, StatusGuard],
    children: [
      {path: '', component: PageHomeComponent},
      {path: 'on-live', component: PageOnLiveComponent},
      {path: 'my-list', component: PageMyListComponent},

      {path: 'category/:categorySlug', component: PageCategoryComponent},
      {path: 'search', component: PageSearchComponent},
      {path: 'profile', component: PageProfileComponent},
      {path: 'change-profile-image', component: PageChangeProfileImageComponent},
      {path: 'change-profile-password', component: PageChangeProfilePasswordComponent},
      {path: 'change-profile-language', component: PageChangeProfileLanguageComponent},
      {path: 'usage-conditions', component: PageUsageConditionsComponent},
    ]
  },
  {
    path: '',
    component: LayoutBlankComponent,
    canActivate: [StatusGuard],
    children: [
      {path: 'login', component: PageLoginComponent},
      {path: 'recovery-password', component: PageRecoveryPasswordComponent},
      {path: 'reset-password', component: PageResetPasswordComponent},
      {path: 'registration', component: PageRegistrationComponent},
      {path: 'splash-screen', component: BasicSplashScreenComponent},
    ]
  },
  {
    path: '',
    component: LayoutFullscreenComponent,
    canActivate: [HomeGuard],
    children: [
      {path: 'maintenance', component: PageMaintenanceComponent},
    ]
  },
  {
    path: '',
    component: LayoutFullscreenComponent,
    canActivate: [StatusGuard],
    children: [
      {path: 'video/:videoId', component: PageVideoComponent},
    ]
  }
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule {
}
