import {Video} from '../video/video';
import {Moment} from 'moment';

export class Banner {

  constructor(
    public id: string,
    public title: string,
    public description: string,
    public video: Video,
    public poster: {},
    public teamLogos: {},
    public createdAt: Moment,
    public updatedAt: Moment
  ) {
  }
}
