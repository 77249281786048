import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'page-usage-conditions',
  templateUrl: './usage-conditions.component.html',
  styleUrls: ['./usage-conditions.component.scss']
})
export class PageUsageConditionsComponent implements OnInit {

  constructor() { }

  ngOnInit() {
  }

}
