import {Component, OnInit} from '@angular/core';
import {FormBuilder, FormGroup, Validators} from '@angular/forms';
import {PlatformService} from '../../../services/platform/platform.service';
import {AuthService} from '../../../services/auth/auth.service';
import {ActivatedRoute, Router} from '@angular/router';

@Component({
  selector: 'page-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.scss']
})

export class PageLoginComponent implements OnInit {

  form: FormGroup;
  loading = false;
  loadingGuest = false;
  submitted = false;
  error: string;

  constructor(
    private formBuilder: FormBuilder,
    public platformService: PlatformService,
    private route: ActivatedRoute,
    private authService: AuthService,
    private router: Router
  ) {

    if (this.authService.isAuthenticated()) {
      this.router.navigate(['/']);
    }
  }

  ngOnInit() {

    this.form = this.formBuilder.group({
      email: ['', [Validators.required, Validators.email]],
      password: ['', Validators.required]
    });
    this.guestLogin();
  }

  get f() {
    return this.form.controls;
  }

  guestLogin() {
    this.loadingGuest = true;
    this.authService.guestLogin()
      .then(data => {
        this.router.navigate(['']);
      })
      .catch(data => {
        this.error = data;
        this.loadingGuest = false;
      });
  }

  onSubmit() {

    this.submitted = true;

    if (this.form.invalid) {
      return;
    }

    this.loading = true;

    this.authService.login(
      {
        email: this.f.email.value,
        password: this.f.password.value
      })
      .then(data => {
        this.router.navigate(['']);
      })
      .catch(data => {
        this.error = data;
        this.loading = false;
      });
  }
}
