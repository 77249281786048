import {Component, OnInit} from '@angular/core';
import {ActivatedRoute} from '@angular/router';
import {VideoService} from '../../../../model/video/video.service';
import {Video} from '../../../../model/video/video';

@Component({
  selector: 'app-video',
  templateUrl: './video.component.html',
  styleUrls: ['./video.component.scss']
})
export class PageVideoComponent implements OnInit {

  video: Video;

  constructor(
    private route: ActivatedRoute,
    private videoService: VideoService
  ) {
  }

  ngOnInit() {
    const videoId = this.route.snapshot.params.videoId;
    this.videoService.get(videoId).then(video => this.video = video);
  }
}
