import {Component, OnInit, ViewEncapsulation} from '@angular/core';
import {Playlist} from '../../../../model/playlist/playlist';
import {Category} from '../../../../model/category/category';
import {CategoryService} from '../../../../model/category/category.service';
import {ActivatedRoute, ParamMap, NavigationEnd, Router} from '@angular/router';
import {VideoService} from '../../../../model/video/video.service';
import {TranslationService} from '../../../services/translation/translation.service';
import {switchMap} from 'rxjs/operators';

@Component({
  selector: 'page-category',
  templateUrl: './category.component.html',
  styleUrls: ['./category.component.scss'],
  encapsulation: ViewEncapsulation.None
})

export class PageCategoryComponent implements OnInit {

  category: Category;

  onLivePlaylist: Playlist;
  lastWeekPlaylist: Playlist;
  lastMonthPlaylist: Playlist;

  constructor(
    private translationService: TranslationService,
    private categoryService: CategoryService,
    private videoService: VideoService,
    private route: ActivatedRoute,
    private router: Router,
  ) {
    this.router.events.subscribe(val => {
      if (val instanceof NavigationEnd) {
        this.fetchCategory();
      }
    });
  }

  ngOnInit() {

    this.route.paramMap.subscribe(params => {
      this.fetchCategory().then(category => {

        this.category = category;

        if (!category.hasChildrenCategories()) {
          this.fetchPlaylists();
        }
      });
    });
  }

  fetchCategory() {
    let categorySlug = this.route.snapshot.params.categorySlug;
    return this.categoryService.getBySlug(categorySlug);
  }

  fetchPlaylists() {
    this.fetchOnLivePlaylist();
    this.fetchLastWeekPlaylist();
    this.fetchLastMonthPlaylist();
  }

  fetchOnLivePlaylist() {

    this.videoService.getList(
      {
        onLive: true,
        categoryId: this.category.id
      }
    ).then(videos => {
      this.onLivePlaylist = new Playlist(
        this.translationService.get('general.on_live'),
        videos,
        null);
    });
  }

  fetchLastWeekPlaylist() {

    this.videoService.getList(
      {
        categoryId: this.category.id,
        order: 'date',
        last: 'week'
      }
    ).then(videos => {
      this.lastWeekPlaylist = new Playlist(
        this.translationService.get('general.last_week'),
        videos,
        null
      );
    });
  }

  fetchLastMonthPlaylist() {

    this.videoService.getList(
      {
        categoryId: this.category.id,
        order: 'date',
        last: ''
      }
    ).then(videos => {
      this.lastMonthPlaylist = new Playlist(
        this.translationService.get('general.last_month'),
        videos,
        null
      );
    });
  }
}
