import {Component, EventEmitter, Input, Output, ViewEncapsulation} from '@angular/core';
import {Video} from '../../../../model/video/video';

@Component({
  selector: 'basic-video-card',
  templateUrl: './video-card.component.html',
  encapsulation: ViewEncapsulation.None
})

export class BasicVideoCardComponent {
  @Input() video: Video;
  @Input() withProgressBar: boolean;
  @Output() onFocus = new EventEmitter<Video>();

  constructor() {
  }

  handleOnFocus() {
    this.onFocus.emit(this.video);
  }
}
