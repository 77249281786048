import {AfterContentInit, Directive, ElementRef, Input} from '@angular/core';

@Directive({
  selector: '[autofocusable]'
})
export class AutofocusableDirective implements AfterContentInit {

  @Input() autofocusableSelector: string;
  @Input() autofocusableCondition: any;

  public constructor(private el: ElementRef) {

  }

  public ngAfterContentInit() {

    if (typeof this.autofocusableCondition !== 'undefined' && !this.autofocusableCondition) {
      return;
    }

    this.doFocus();
  }

  private doFocus() {

    setTimeout(() => {

      let element: HTMLElement = this.el.nativeElement;

      if (this.autofocusableSelector) {
        element = element.querySelector(this.autofocusableSelector);
      }

      element.focus();
    });
  }

}
