// Modules
import {BrowserModule} from '@angular/platform-browser';
import {ElementRef, NgModule} from '@angular/core';
import {AppRoutingModule} from './app-routing.module';
import {SlickCarouselModule} from 'ngx-slick-carousel';
import {FormsModule, ReactiveFormsModule} from '@angular/forms';

// App Component
import {AppComponent} from './app.component';

// Layout Components
import {LayoutMainComponent} from './components/layouts/main/main.component';
import {LayoutBlankComponent} from './components/layouts/blank/blank.component';
import {LayoutFullscreenComponent} from './components/layouts/fullscreen/fullscreen.component';

// Basic Components
import {BasicBannerComponent} from './components/basic/banner/banner.component';
import {BasicBannersCarouselComponent} from './components/basic/banners-carousel/banners-carousel.component';
import {BasicPlaylistCarouselComponent} from './components/basic/playlist-carousel/playlist-carousel.component';
import {BasicPlaylistCarouselDesktopComponent} from './components/basic/playlist-carousel/platform/playlist-carousel-desktop/playlist-carousel-desktop.component';
import {BasicPlaylistCarouselSmartTVComponent} from './components/basic/playlist-carousel/platform/playlist-carousel-smart-tv/playlist-carousel-smart-tv.component';
import {BasicSplashScreenComponent} from './components/basic/splash-screen/splash-screen.component';
import {BasicTheFooterComponent} from './components/basic/the-footer/the-footer.component';
import {BasicHeaderDesktopComponent} from './components/basic/header-desktop/header-desktop.component';
import {BasicHeaderMobileComponent} from './components/basic/header-mobile/header-mobile.component';
import {BasicVideoCardComponent} from './components/basic/video-card/video-card.component';
import {BasicVideoGridComponent} from './components/basic/video-grid/video-grid.component';
import {BasicLoadingComponent} from './components/basic/loading/loading.component';
import {BasicSmartKeyboardComponent} from './components/basic/smart-keyboard/smart-keyboard.component';
import {BasicPlatformSwitcherComponent} from './components/basic/platform-switcher/platform-switcher.component';
import {BasicSearchBoxComponent} from './components/basic/search-box/search-box.component';
import {BasicCategoryCarouselComponent} from './components/basic/category-carousel/category-carousel.component';
import {BasicCategoryCarouselDesktopComponent} from './components/basic/category-carousel/platform/category-carousel-desktop/category-carousel-desktop.component';
import {BasicCategoryCarouselSmartTVComponent} from './components/basic/category-carousel/platform/category-carousel-smart-tv/category-carousel-smart-tv.component';
import {BasicShowAllComponent} from './components/basic/show-all/show-all.component';
import {BasicPlaylistCarouselListComponent} from './components/basic/playlist-carousel-list/playlist-carousel-list.component';
import {BasicVideoPlayerComponent} from './components/basic/video-player/video-player.component';
import {BasicBackgroundComponent} from './components/basic/page-background/page-background.component';

// Form Controls Components
import {FormControlTextComponent} from './components/form-controls/text/text.component';
import {FormControlCounterComponent} from './components/form-controls/counter/counter-form-control.component';
import {FormControlErrorsComponent} from './components/form-controls/errors/errors.component';

// Page Components
import {PageLoginComponent} from './components/pages/login/login.component';
import {PageRecoveryPasswordComponent} from './components/pages/recovery-password/recovery-password.component';
import {PageResetPasswordComponent} from './components/pages/reset-password/reset-password.component';
import {PageRegistrationComponent} from './components/pages/registration/registration.component';

import {PageHomeComponent} from './components/pages/home/home.component';
import {PageOnLiveComponent} from './components/pages/on-live/on-live.component';
import {PageMyListComponent} from './components/pages/my-list/my-list.component';

import {PageSearchComponent} from './components/pages/search/search.component';
import {PageSearchDesktopComponent} from './components/pages/search/platform/search-desktop/search-desktop.component';
import {PageSearchSmartTVComponent} from './components/pages/search/platform/search-smart-tv/search-smart-tv.component';

import {PageCategoryComponent} from './components/pages/category/category.component';

import {PageProfileComponent} from './components/pages/profile/profile.component';
import {PageUsageConditionsComponent} from './components/pages/usage-conditions/usage-conditions.component';
import {PageChangeProfileImageComponent} from './components/pages/change-profile-image/change-profile-image.component';
import {PageChangeProfilePasswordComponent} from './components/pages/change-profile-password/change-profile-password.component';
import {PageChangeProfileLanguageComponent} from './components/pages/change-profile-language/change-profile-language.component';

import {PageVideosGridComponent} from './components/pages/videos-grid/videos-grid.component';
import {PageVideoComponent} from './components/pages/video/video.component';

import {PageMaintenanceComponent} from './components/pages/maintenance/maintenance.component';

// Translation
import {TranslateLoader, TranslateModule} from '@ngx-translate/core';
import {TranslateHttpLoader} from '@ngx-translate/http-loader';
import {HTTP_INTERCEPTORS, HttpClient, HttpClientModule} from '@angular/common/http';
import {TranslationService} from './services/translation/translation.service';

// Auth
import {JwtModule} from '@auth0/angular-jwt';
import {JwtInterceptorService} from './services/jwt-interceptor/jwt-interceptor.service';
import {AuthService} from './services/auth/auth.service';

// Directives
import {AutofocusableDirective} from './directives/autofocusable/autofocusable.directive';
import {FocusableDirective} from './directives/focusable/focusable.directive';
import {ElementQueryDirective} from './directives/element-query/element-query.directive';

// Pipes
import {MinuteSecondsPipe} from './pipes/minute-seconds';

export function jwtTokenGetter() {
  return localStorage.getItem(AuthService.TOKEN_KEY);
}

@NgModule({
  declarations: [

    AppComponent,

    // Layouts
    LayoutMainComponent,
    LayoutBlankComponent,
    LayoutFullscreenComponent,

    // Basic
    BasicSplashScreenComponent,
    BasicBannerComponent,
    BasicBannersCarouselComponent,
    BasicPlaylistCarouselComponent,
    BasicPlaylistCarouselDesktopComponent,
    BasicPlaylistCarouselSmartTVComponent,
    BasicHeaderDesktopComponent,
    BasicHeaderMobileComponent,
    BasicTheFooterComponent,
    BasicVideoCardComponent,
    BasicVideoGridComponent,
    BasicLoadingComponent,
    BasicSmartKeyboardComponent,
    BasicPlatformSwitcherComponent,
    BasicSearchBoxComponent,
    BasicCategoryCarouselComponent,
    BasicCategoryCarouselDesktopComponent,
    BasicCategoryCarouselSmartTVComponent,
    BasicBackgroundComponent,
    BasicShowAllComponent,
    BasicPlaylistCarouselListComponent,
    BasicVideoPlayerComponent,

    // Form Controls
    FormControlTextComponent,
    FormControlErrorsComponent,
    FormControlCounterComponent,

    // Pages
    PageLoginComponent,
    PageRecoveryPasswordComponent,
    PageResetPasswordComponent,
    PageRegistrationComponent,
    PageProfileComponent,

    PageHomeComponent,
    PageOnLiveComponent,
    PageMyListComponent,
    PageSearchComponent,
    PageSearchDesktopComponent,
    PageSearchSmartTVComponent,

    PageCategoryComponent,
    PageVideoComponent,
    PageVideosGridComponent,

    PageChangeProfileImageComponent,
    PageChangeProfilePasswordComponent,
    PageChangeProfileLanguageComponent,
    PageUsageConditionsComponent,

    PageMaintenanceComponent,

    // Directives
    AutofocusableDirective,
    FocusableDirective,
    ElementQueryDirective,

    // Pipes
    MinuteSecondsPipe,
  ],
  imports: [
    BrowserModule,
    AppRoutingModule,
    SlickCarouselModule,
    FormsModule,
    ReactiveFormsModule,
    HttpClientModule,
    TranslateModule.forRoot({
      loader: {
        provide: TranslateLoader,
        useFactory: (http: HttpClient) => {
          return new TranslateHttpLoader(http);
        },
        deps: [HttpClient]
      }
    }),
    JwtModule.forRoot({
      config: {
        tokenGetter: jwtTokenGetter
      }
    })
  ],
  providers: [
    HttpClient,
    TranslationService,
    {
      provide: HTTP_INTERCEPTORS,
      useClass: JwtInterceptorService,
      multi: true
    }
  ],
  bootstrap: [AppComponent]
})
export class AppModule {
}
