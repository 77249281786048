import {Moment} from 'moment';

export class VideoAd {

  constructor(
    public id: string,
    public url: string,
    public skipAt: number,
    public when: string,
    public startsAfter: number,
    public createdAt: Moment,
    public updatedAt: Moment
  ) {
  }
}
