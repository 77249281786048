import {AfterViewInit, Component, ViewEncapsulation} from '@angular/core';
import {TranslationService} from './services/translation/translation.service';
import {HttpClient} from '@angular/common/http';
import environment from '../environments/environment';
import {SpatialNavigationService} from './services/spatial-navigation/spatial-navigation.service';
import {ElementQueriesService} from './services/element-queries/element-queries.service';
import {PlatformService} from './services/platform/platform.service';
import {BackButton} from './services/back-button/back-button.service';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  encapsulation: ViewEncapsulation.None
})

export class AppComponent implements AfterViewInit {

  showPlatformSwitcher: boolean = !environment.production && !environment.smartTV;

  constructor(private translationService: TranslationService,
              private http: HttpClient,
              private spatialNavigationService: SpatialNavigationService,
              private elementQueriesService: ElementQueriesService,
              private platformService: PlatformService,
              private backButton: BackButton
  ) {

    translationService.init();

    elementQueriesService.init();
  }

  ngAfterViewInit(): void {

    if (this.platformService.isSmartTV()) {
      this.spatialNavigationService.init();
    }
  }
}
