import {Component, OnInit, forwardRef, Input, OnChanges, AfterViewInit} from '@angular/core';
import {FormControl, ControlValueAccessor, NG_VALUE_ACCESSOR, NG_VALIDATORS} from '@angular/forms';
import {SpatialNavigationService} from '../../../services/spatial-navigation/spatial-navigation.service';


@Component({
  selector: 'form-control-text',
  templateUrl: './text.component.html',
  providers: [
    {provide: NG_VALUE_ACCESSOR, useExisting: forwardRef(() => FormControlTextComponent), multi: true},
    {provide: NG_VALIDATORS, useExisting: forwardRef(() => FormControlTextComponent), multi: true}
  ]
})
export class FormControlTextComponent implements ControlValueAccessor, OnChanges {
  constructor(private spatialNavigationService: SpatialNavigationService) {
  }

  propagateChange: any = () => {
  };
  validateFn: any = () => {
  };

  @Input('value') _value = '';
  @Input() type = 'text';
  @Input() label;
  @Input() placeholder;
  @Input() showError = false;
  @Input() formControlName;
  @Input() errors: FormControl;
  @Input() disabled = false;

  get value() {
    return this._value;
  }

  set value(val) {
    this._value = val;
    this.propagateChange(val);
  }

  ngOnChanges(inputs) {
    this.propagateChange(this.value);
  }

  writeValue(value) {
    if (value) {
      this.value = value;
    }
  }

  registerOnChange(fn) {
    this.propagateChange = fn;
  }

  registerOnTouched() {
  }

  validate(c: FormControl) {
    return this.validateFn(c);
  }

  setDisabledState(isDisabled: boolean): void {
  }
}
