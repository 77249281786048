import {Component, Input, AfterViewInit, ViewChildren, HostListener} from '@angular/core';
import {Video} from '../../../../../../model/video/video';
import {VideoService} from '../../../../../../model/video/video.service';
import {HelpersService} from '../../../../../services/helpers/helpers.service';

@Component({
  selector: 'page-search-smart-tv',
  templateUrl: './search-smart-tv.component.html',
  styleUrls: ['./search-smart-tv.component.scss']
})

export class PageSearchSmartTVComponent implements AfterViewInit {
  @ViewChildren('input') inputViewChildren;

  videos: Video[] = [];
  searching = false;
  loadingVideos = false;
  timer: number = null;
  timeout = 1000;
  searchParams = {
    query: '',
    page: 1,
    limit: 12
  };
  existsMoreVideosInCurrentSearch: boolean;

  constructor(private videoService: VideoService) {
  }

  get query(): any {
    return this.searchParams.query;
  }

  @Input()
  set query(criteria: any) {
    this.searchParams.query = criteria;
    this.searchParams.page = 1;
    this.existsMoreVideosInCurrentSearch = false;
    this.videos = [];

    if (this.timer) {
      clearTimeout(this.timer);
    }

    this.searching = true;
    this.timer = setTimeout(async () => {
      this.videos = await this.videoService.search(this.searchParams);
      if (this.videos.length == this.searchParams.limit) {
        this.existsMoreVideosInCurrentSearch = true;
      }
      this.searching = false;
    }, this.timeout);
  }


  ngAfterViewInit() {
    // this.inputViewChildren.first.nativeElement.focus();
    // window.addEventListener('scroll', () => this.handleScroll());
  }

  async loadMoreResults() {

    if (!this.existsMoreVideosInCurrentSearch) {
      return;
    }

    this.searchParams.page++;
    this.loadingVideos = true;
    this.videoService.search(this.searchParams)
      .then((videos: Video[]) => {
        this.loadingVideos = false;
        this.videos = [...this.videos, ...videos];
        if (videos.length < this.searchParams.limit) {
          this.existsMoreVideosInCurrentSearch = true;
        }
      });
  }
}
