import {HttpClient} from '@angular/common/http';
import {Injectable} from '@angular/core';
import environment from '../../../environments/environment';
import {Observable} from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class ApiService {

  apiUrl: string;

  constructor(private httpClient: HttpClient) {
    this.apiUrl = environment.api;
  }

  delete(url: string, options?: {}, toPromise: boolean = true): any {
    const response = this.httpClient.delete(this.apiUrl + url, options);
    return this.handleResponse(response, toPromise);
  }

  post(url: string, body: any | null, options?: {}, toPromise: boolean = true): any {
    const response = this.httpClient.post(this.apiUrl + url, body, options);
    return this.handleResponse(response, toPromise);
  }

  get(url: string, options?: {}, toPromise: boolean = true): any {
    const response = this.httpClient.get(this.apiUrl + url, options);
    return this.handleResponse(response, toPromise);
  }

  put(url: string, body: any | null, options?: {}, toPromise: boolean = true): any {
    const response = this.httpClient.put(this.apiUrl + url, body, options);
    return this.handleResponse(response, toPromise);
  }

  patch(url: string, body: any | null, options?: {}, toPromise: boolean = true): Observable<any> | Promise<any> {
    const response = this.httpClient.put(this.apiUrl + url, body, options);
    return this.handleResponse(response, toPromise);
  }

  private handleResponse(response, toPromise: boolean = true) {
    return toPromise ? response.toPromise() : response;
  }
}
