import {Component, Input, AfterViewInit, ViewChild} from '@angular/core';
import {Playlist} from '../../../../../../model/playlist/playlist';
import {SlickCarouselComponent} from 'ngx-slick-carousel';
import jQuery from 'jquery';

@Component({
  selector: 'basic-playlist-carousel-smart-tv',
  templateUrl: './playlist-carousel-smart-tv.component.html',
  styleUrls: ['./playlist-carousel-smart-tv.component.scss']
})

export class BasicPlaylistCarouselSmartTVComponent implements AfterViewInit {
  @ViewChild('slickCarousel') slickCarousel: SlickCarouselComponent;
  @Input() playlist: Playlist;

  slidesToShow = 4;

  slideConfig = {
    slidesToShow: this.slidesToShow,
    slidesToScroll: 1,
    arrows: false,
    infinite: false,
    swipe: false,
    accessibility: false,
    speed: 100 // ms
  };

  showAllHidden = false;

  slickCarouselNative: Node;

  slickBeforeChange(e) {
    this.showAllHidden = e.nextSlide !== 0;
  }

  ngAfterViewInit() {
    if (this.slickCarousel) {
      this.slickCarouselNative = (this.slickCarousel as any).el.nativeElement;
    }
  }

  onKeyDown(event) {

    // Left

    if (event.keyCode === 37) {

      setTimeout(() => {
        if (!this.getFocusedItem().hasClass('slick-active')) {
          this.slickCarousel.slickGoTo(this.getFocusedItemIndex());
        }
      });
    }

    // Right

    if (event.keyCode === 39) {

      setTimeout(() => {
        // if (this.getFocusedItemIndex() < this.playlist.videos.length - this.slidesToShow + 1) {
        this.slickCarousel.slickGoTo(this.getFocusedItemIndex());
        // }
      });
    }
  }

  getFocusedItem() {
    const focusedVideoCard = jQuery(this.slickCarouselNative).find('.VideoCard:focus');
    return jQuery(focusedVideoCard).closest('[data-slick-index]');
  }

  getFocusedItemIndex() {
    return this.getFocusedItem().data('slick-index');
  }
}

