import {Injectable} from '@angular/core';
import {CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot, UrlTree, Router} from '@angular/router';
import {Observable} from 'rxjs';
import {StatusService} from '../../services/status/status.service';

@Injectable({
  providedIn: 'root'
})
export class StatusGuard implements CanActivate {

  constructor(private statusService: StatusService, private router: Router) {
  }

  canActivate(
    next: ActivatedRouteSnapshot,
    state: RouterStateSnapshot): Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree {
    const promise = new Promise((resolve, reject) => {
      this.statusService.checkStatus()
        .then(data => {
          resolve(data.data.status === this.statusService.STATUS_UP);
        });
    });

    return promise.then(up => {
      if (!up) {
        this.router.navigate(['/maintenance']);
      }

      return !!up;
    });
  }
}
