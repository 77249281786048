export class User {

  private imagePath: string = 'assets/img/profile/';
  private defaultAvatar: string = 'profile_001.png';

  constructor(
    public id: string,
    public email: string,
    public name: string,
    public surname: string,
    public image: string,
    public language: string
  ) {
  }

  getImagePath() {
    return this.image ? this.imagePath + this.image : this.imagePath + this.defaultAvatar;
  }
}
