import {Video} from '../video/video';

export class Playlist {

  constructor(
    public name: string,
    public videos: Video[],
    public showAllUrl: string,
    public withProgressBar: boolean = false
  ) {
  }
}
