import {Component, EventEmitter, OnInit, Output, ViewChild} from '@angular/core';
import {SearchService} from '../../../services/search/search.service';

@Component({
  selector: 'basic-search-box',
  templateUrl: './search-box.component.html',
  styleUrls: ['./search-box.component.scss']
})

export class BasicSearchBoxComponent {

  isActive = false;
  query: string;

  constructor(public searchService: SearchService) {
  }

  deactivate() {
    this.isActive = false;
  }

  activate() {
    this.isActive = true;
    this.getCurrentQuery();
  }

  onKeyup(event) {
    this.searchService.onSearch.emit(this.query);
  }

  getCurrentQuery() {
    this.query = this.searchService.getQuery();
  }
}
