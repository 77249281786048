import {Component, Input, OnInit} from '@angular/core';
import {Banner} from '../../../../model/banner/banner';

@Component({
  selector: 'basic-banner',
  templateUrl: './banner.component.html',
  styleUrls: ['./banner.component.scss']
})

export class BasicBannerComponent implements OnInit {
  @Input() banner: Banner;

  public isMobile = false;

  ngOnInit() {
    this.isMobile = window.innerWidth < 768;
  }
}
