import {
  AfterViewInit,
  ChangeDetectorRef,
  Component,
  HostListener,
  Input,
  OnDestroy,
  ViewChild,
  ViewEncapsulation
} from '@angular/core';
import Player from '@vimeo/player/dist/player';
import {Video} from '../../../../model/video/video';
import {PlatformService} from '../../../services/platform/platform.service';
import {Location} from '@angular/common';
import {HelpersService} from '../../../services/helpers/helpers.service';
import {VideoAd} from '../../../../model/video-ad/video-ad';
import {VideoService} from '../../../../model/video/video.service';
import {fromEvent} from 'rxjs';
import {AuthService} from '../../../services/auth/auth.service';

@Component({
  selector: 'basic-video-player',
  templateUrl: './video-player.component.html',
  styleUrls: ['./video-player.component.scss'],
  encapsulation: ViewEncapsulation.None
})

export class BasicVideoPlayerComponent implements AfterViewInit, OnDestroy {

  @Input('video') video: Video;
  @Input('loadingPosition') loadingPosition = 'center';
  @ViewChild('videoPlayerElement') videoPlayerElement;
  @ViewChild('videoIframeContainer') videoIframeContainer;
  @ViewChild('videoAdIframeContainer') videoAdIframeContainer;
  @ViewChild('playButton') playButton;
  @ViewChild('adSkipButton') adSkipButton;
  @ViewChild('backwardButton') backwardButton;
  @ViewChild('forwardButton') forwardButton;

  private player: Player;
  private playing: boolean;
  public playMuted = false;
  private playerDefaultOptions: object = {
    autoplay: false,
    controls: false,
    title: false,
    muted: this.playMuted
  };

  public playAfterBuffering = true;
  private forwardAfterBuffering = false;
  private backwardAfterBuffering = false;
  private duration: number;
  private currentTime = 0;
  private currentPercent = 0;
  private bufferedPercent = 0;
  private xwardSecondsStep = 1;
  private currentSecondsStep = 1;
  private limitSecondsStep = 8;
  private waitNextSeek = 250;

  private adPlayer: Player;
  private adPlaylist: VideoAd[];
  private adCurrentVideo: VideoAd;
  private adPlayTimeout = 0;
  private adPlaylistIndex: number;
  private adPlaylistWhen: string;
  public adPlaying = true;
  public adPlayMuted = false;
  private adPlayerDefaultOptions: object = {
    autoplay: true,
    controls: false,
    title: false,
    muted: this.adPlayMuted
  };
  public adSkipIn: number;
  public adSkipEnabled: boolean;
  public adCountDown: number;
  public adSkippable: boolean;


  public buffering = true;
  private isFullscreen: boolean;
  private interfaceActive = false;
  private deactivateInterfaceTimer;
  private videoReady = false;


  constructor(
    private location: Location,
    public platformService: PlatformService,
    private helpersService: HelpersService,
    private videoService: VideoService,
    private cdRef: ChangeDetectorRef
  ) {
    if (this.platformService.isSmartTV()) {
      // Allow remote keys on Samsung devices
      if (window.hasOwnProperty('tizen')) {
        // @ts-ignore
        window.tizen.tvinputdevice.registerKeyBatch(['MediaPlayPause', 'MediaRewind', 'MediaFastForward', 'MediaPlay', 'MediaPause', 'MediaStop']);
      }
    }
  }

  // tslint:disable-next-line:use-life-cycle-interface
  ngAfterViewChecked() {
    this.cdRef.detectChanges();
  }

  ngAfterViewInit() {

    this.loadVideo();
    this.attachRemoteControllerEvents();
    this.adAttachEvents();
    this.adsBeforePlaylistInit();
  }

  @HostListener('document:keypress', ['$event'])
  enterHandler(event) {
    if (event.charCode != 13) {
      return false;
    }

    if (this.adPlaying) {
      return false;
    }

    if (event.target.classList.contains('VideoPlayer-button')) {
      return false;
    }

    this.togglePlay();
    this.activateInterface();
  }

  attachRemoteControllerEvents() {
    if (this.platformService.isSmartTV()) {
      fromEvent(document, 'keydown').subscribe((event: KeyboardEvent) => {
        if (this.adPlaying) {
          return false;
        }
        this.activateInterface();
        if (event.keyCode === 417) {
          this.focusForwardButton();
          this.forward();
        } else if (event.keyCode === 412) {
          this.focusBackwardButton();
          this.backward();
          // MediaPlayPause
        } else if (event.keyCode === 10252) {
          this.focusPlayButton();
          this.togglePlay();
          // MediaPlay
        } else if (event.keyCode === 415) {
          if (!this.playing) {
            this.focusPlayButton();
            this.togglePlay();
          }
          // MediaPause
        } else if (event.keyCode === 19) {
          if (this.playing || this.backwardAfterBuffering || this.forwardAfterBuffering) {
            this.focusPlayButton();
            this.currentSecondsStep = this.xwardSecondsStep;
            this.pause();
          }
          // MediaStop
        } else if (event.keyCode === 413) {
            this.goBack();
        }
      });
    }
  }

  activateInterfaceFlash() {
    if (!this.interfaceActive) {
      this.activateInterface();
    }
    this.deactivateInterface();
  }

  adsBeforePlaylistInit() {
    this.adPlaylist = this.video.ads.before;
    this.adPlaylistIndex = -1;
    this.adPlaylistWhen = 'before';
    this.adPlaylistNext();
  }

  adsAfterPlaylistInit() {
    this.adPlaylist = this.video.ads.after;
    this.adPlaylistIndex = -1;
    this.adPlaylistWhen = 'after';
    this.adPlaylistNext();
  }

  adPlaylistNext() {

    this.adPlaying = true;
    this.buffering = false;

    this.adSkipButton.nativeElement.focus();

    this.adPlaylistIndex++;

    if (!this.adPlaylist || this.adPlaylist.length - 1 < this.adPlaylistIndex) {
      this.adPlaying = false;
      this.buffering = true;
      const adPlaylistEnded = new Event('ads_' + this.adPlaylistWhen + '_ended');
      this.videoPlayerElement.nativeElement.dispatchEvent(adPlaylistEnded);
      return;
    }

    this.adCurrentVideo = this.adPlaylist[this.adPlaylistIndex];
    this.adSkippable = this.adCurrentVideo.skipAt > 0;
    const videoUrl = this.adCurrentVideo.url;

    if (!this.adPlayer) {
      if (this.platformService.isDesktop()) {
        // @ts-ignore
        this.adPlayerDefaultOptions.muted = true;
        this.adPlayMuted = true;
      }
      const options = JSON.parse(JSON.stringify(this.adPlayerDefaultOptions));
      options.id = videoUrl;
      this.adPlayer = new Player(this.videoAdIframeContainer.nativeElement, options);
      this.adPlayerAttachEvents();
    } else {
      this.adPlayer.loadVideo(videoUrl);
    }

  }

  goBack() {
    this.location.back();
  }

  loadVideo() {

    const options = JSON.parse(JSON.stringify(this.playerDefaultOptions));
    options.id = this.video.url;
    if (this.platformService.isDesktop()) {
      this.playMuted = true;
      options.muted = true;
    }
    this.player = new Player(this.videoIframeContainer.nativeElement, options);
    this.playerAttachEvents();
  }

  activateInterface() {

    this.interfaceActive = true;

    clearTimeout(this.deactivateInterfaceTimer);

    if (this.platformService.isSmartTV()) {
      // this.focusPlayButton();
    }
  }

  deactivateInterface() {

    clearTimeout(this.deactivateInterfaceTimer);

    this.deactivateInterfaceTimer = setTimeout(() => {
      this.interfaceActive = false;

      this.focusPlayButton();

    }, 3000);
  }

  deactivateInterfaceTimerReset() {
    this.deactivateInterface();
  }

  togglePlay() {
    this.currentSecondsStep = this.xwardSecondsStep;
    if (!this.playing) {
      this.play();
    } else {
      this.pause();
    }
  }

  toggleMute() {
    this.adPlayer.setMuted(!this.adPlayMuted).then(muted => {
      this.adPlayMuted = muted;
    }).catch(error => {
      console.log('Error toggling mute: ', error);
    });
  }

  toggleMutePlayer() {
    this.player.setMuted(!this.playMuted).then(muted => {
      this.playMuted = muted;
    }).catch(error => {
      console.log('Error toggling mute on main Player: ', error);
    });
  }

  playerAttachEvents() {

    this.player.on('error', (data) => {
      // General player errors, but we can catch them on each promise call
    });

    this.player.on('play', (data) => {
      this.playing = true;
      this.deactivateInterface();
    });

    this.player.on('pause', (data) => {
      if (!this.buffering) {
        this.playing = false;
        this.activateInterface();
      }
    });

    this.player.on('timeupdate', (data) => {
      if (typeof this.duration === 'undefined') {
        if (!this.video.interruptedAt) {
          this.videoService.updateWatchedTime(this.video.id);
        } else {
          const newTime: number = Math.round(this.video.interruptedAt * data.duration / 100);
          this.player.setCurrentTime(newTime);
        }
      }
      this.currentTime = data.seconds;
      this.currentPercent = data.percent;
      this.duration = data.duration;

      if (this.playing) {
        this.buffering = false;
      }
      if (this.playAfterBuffering) {
        this.play();
      }
    });

    this.player.on('seeked', (data) => {
      this.buffering = false;
      if (this.forwardAfterBuffering) {
        this.keepForwarding();
      } else if (this.backwardAfterBuffering) {
        this.keepBackwarding();
      }
    });

    this.player.on('progress', (data) => {
      this.bufferedPercent = data.percent;
    });

    this.player.on('ended', (data) => {
      this.adsAfterPlaylistInit();
      this.videoService.markAsViewed(this.video.id);
    });

    this.player.on('loaded', (data) => {
      this.videoReady = true;
    });
  }

  adAttachEvents() {

    this.videoPlayerElement.nativeElement.addEventListener('ads_before_ended', (e) => {
      this.play();
      this.focusPlayButton();
    });

    this.videoPlayerElement.nativeElement.addEventListener('ads_after_ended', (e) => {
      this.goBack();
    });
  }

  adPlayerAttachEvents() {

    this.adPlayer.on('ended', (data) => {
      this.adPlaylistNext();
    });

    this.adPlayer.on('timeupdate', (data) => {
      this.adCountDown = data.duration - data.seconds;
      if (!this.adSkipEnabled) {
        this.adSkipIn = Math.ceil(this.adCurrentVideo.skipAt - data.seconds);
        if (!this.adCurrentVideo.skipAt || this.adSkipIn < 0) {
          this.adSkipEnabled = true;
          this.focusAdSkipButton();
        }
      }
      // SmartTV workaroud. During last seconds of ads events are not fired, so we need to add and refresh a timeout to be sure that the ad goes automatically to the video when finished
      if (this.adCountDown < 15) {
        if (this.adPlayTimeout > 0) {
          clearTimeout(this.adPlayTimeout);
        }
        this.adPlayTimeout = setTimeout(() => {
          this.adPlaylistNext();
        }, this.adCountDown * 1000);
      }
    });
  }

  play() {

    this.playAfterBuffering = false;
    this.forwardAfterBuffering = false;
    this.backwardAfterBuffering = false;

    // We experienced race condition problems on emulators & TV, we have to set a timeout and reload the video in case the start method is not properly called
    const timeOutPromise = new Promise((resolve, reject) => {
      setTimeout(resolve, 5000, 'retry');
    });

    Promise.race([timeOutPromise, this.player.play()]).then((value) => {
      if (value === 'retry') {
        this.player.loadVideo(this.video.url).then((id) => {
          this.play();
        });
      }
    });
    // End of race condition hack
  }

  pause() {
    this.player.pause();
    this.playing = false;
    this.forwardAfterBuffering = false;
    this.backwardAfterBuffering = false;
    this.videoService.setInterruptionPoint(this.video.id, this.currentPercent);
  }

  setBuffering(playAfterBuffering = true) {
    this.buffering = true;
    if (this.playing) {
      this.playAfterBuffering = playAfterBuffering;
      this.pause();
    }
  }

  forward() {
    if (this.backwardAfterBuffering && this.currentSecondsStep / 2 >= this.xwardSecondsStep) {
      this.currentSecondsStep = this.currentSecondsStep / 2;
    } else if (this.forwardAfterBuffering) {
      if (this.currentSecondsStep < this.limitSecondsStep) {
        this.currentSecondsStep = this.currentSecondsStep * 2;
      }
    } else {
      this.currentSecondsStep = this.xwardSecondsStep;
      this.keepForwarding(0);
    }
  }

  keepForwarding(wait = this.waitNextSeek) {
    const step = 2 ** this.currentSecondsStep;
    this.setBuffering(false);
    this.backwardAfterBuffering = false;
    this.forwardAfterBuffering = true;
    const newTime: number = (this.currentTime + step) > this.duration ? this.duration : this.currentTime + step;
    setTimeout(() => {
      this.player.setCurrentTime(newTime);
    }, wait);
  }

  backward() {
    if (this.forwardAfterBuffering && (this.currentSecondsStep / 2 >= this.xwardSecondsStep)) {
      this.currentSecondsStep = this.currentSecondsStep / 2;
    } else if (this.backwardAfterBuffering) {
      if (this.currentSecondsStep < this.limitSecondsStep) {
        this.currentSecondsStep = this.currentSecondsStep * 2;
      }
    } else {
      this.currentSecondsStep = this.xwardSecondsStep;
      this.keepBackwarding(0);
    }
  }

  keepBackwarding(wait = this.waitNextSeek) {
    const step = 2 ** this.currentSecondsStep;
    this.setBuffering(false);
    this.forwardAfterBuffering = false;
    this.backwardAfterBuffering = true;
    const newTime: number = (this.currentTime - step) < 0 ? 0 : this.currentTime - step;
    setTimeout(() => {
      this.player.setCurrentTime(newTime);
    }, wait);
  }

  focusPlayButton() {
    setTimeout(() => {
      if (this.playButton) {
        this.playButton.nativeElement.focus();
      }
    }, 50);
  }

  focusForwardButton() {
    setTimeout(() => {
      if (this.forwardButton) {
        this.forwardButton.nativeElement.focus();
      }
    }, 50);
  }

  focusBackwardButton() {
    setTimeout(() => {
      if (this.backwardButton) {
        this.backwardButton.nativeElement.focus();
      }
    }, 50);
  }

  focusAdSkipButton() {
    setTimeout(() => {
      if (this.adSkipButton) {
        this.adSkipButton.nativeElement.focus();
      }
    }, 50);
  }

  setCurrentTime(event) {
    this.setBuffering();
    const clickPercentage = 100 * event.clientX / event.currentTarget.offsetWidth;
    this.player.setCurrentTime(this.duration * clickPercentage / 100);
    this.videoService.setInterruptionPoint(this.video.id, this.currentPercent);
    if (this.forwardAfterBuffering || this.backwardAfterBuffering) {
      this.backwardAfterBuffering = false;
      this.forwardAfterBuffering = false;
      this.togglePlay();
    }
  }

  // On current and total time getters we have to substract one hour to show the "0" hours counter. LG Emulator shows the wrong time, but it's working on real devices
  getCurrentTime() {
    const timeAdjustment = 60 * 60 * 1000;
    return this.currentTime * 1000 - timeAdjustment;
  }

  getTotalTime() {
    const timeAdjustment = 60 * 60 * 1000;
    return this.duration * 1000 - timeAdjustment;
  }

  getCurrentMultiplier() {
    let value = null;
    if (this.forwardAfterBuffering) {
      value = 'x ' + (this.currentSecondsStep * 2) + ' <i class="SearchBox-icon fas fa-fast-forward"></i>';
    } else if (this.backwardAfterBuffering) {
      value = '<i class="SearchBox-icon fas fa-fast-backward"></i> x ' + (this.currentSecondsStep * 2);
    }
    return value;
  }

  fullscreenEnable() {
    this.helpersService.fullscreenOpen(this.videoPlayerElement.nativeElement);
    this.isFullscreen = true;
  }

  fullscreenDisable() {
    this.helpersService.fullscreenClose();
    this.isFullscreen = false;
  }

  adSkip() {
    this.adPlaylistNext();
  }

  ngOnDestroy(): void {
    this.videoService.setInterruptionPoint(this.video.id, this.currentPercent);
  }
}
