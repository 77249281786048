import {Directive, ElementRef} from '@angular/core';
import {ElementQueriesService} from '../../services/element-queries/element-queries.service';

@Directive({
  selector: '[element-query]'
})

export class ElementQueryDirective {

  constructor(el: ElementRef, elementQueriesService: ElementQueriesService) {
    elementQueriesService.init(el.nativeElement.classList[0])
  }
}
