import {Injectable} from '@angular/core';
import {ApiService} from '../../app/services/api/api.service';
import {LanguageAdapter} from './language.adapter';
import {Language} from './language';

@Injectable({
  providedIn: 'root'
})

export class LanguageService {

  constructor(
    private apiService: ApiService,
    private adapter: LanguageAdapter
  ) {

  }

  list(): Promise<Language[]> {
    return new Promise((resolve, reject) => {
      this.apiService.get('/languages')

        .then((response) => {

          const items: Language[] = [];
          response.data.languages.forEach(item => {
            const itemAdapted = this.adapter.adapt(item);
            items.push(itemAdapted);
          });

          resolve(items);
        })

        .catch((response) => {
          reject(response);
        });
    });
  }

}
