import {Injectable} from '@angular/core';
import {ApiService} from '../../app/services/api/api.service';
import {VideoAdapter} from './video.adapter';
import {Video} from './video';
import {HttpParams} from '@angular/common/http';
import {AuthService} from '../../app/services/auth/auth.service';

@Injectable({
  providedIn: 'root'
})

export class VideoService {

  constructor(
    private apiService: ApiService,
    private adapter: VideoAdapter,
    private authService: AuthService,
  ) {

  }

  get(videoId: string): Promise<Video> {
    return new Promise((resolve, reject) => {

      this.apiService.get('/videos/' + videoId)

        .then((response) => {
          const video: Video = this.adapter.adapt(response.data.video);
          resolve(video);
        })

        .catch((response) => {
          reject(response);
        });
    });
  }

  getList(params: any): Promise<Video[]> {
    return new Promise((resolve, reject) => {
      let queryString = '';

      if (params.onLive) {
        queryString += '&on_live=' + (params.onLive ? 1 : 0);
      }
      if (params.categoryId) {
        queryString += '&category_id=' + params.categoryId;
      }
      if (params.includeChildren) {
        queryString += '&include_children=' + params.includeChildren;
      }
      if (params.limit) {
        queryString += '&limit=' + params.limit;
      }
      if (params.order) {
        queryString += '&order=' + params.order;
      }
      if (params.last) {
        queryString += '&last=' + params.last;
      }

      queryString = '?' + queryString.substring(1);

      this.apiService.get('/videos' + queryString)

        .then((response) => {

          const videos: Video[] = [];
          response.data.videos.forEach(item => {
            videos.push(this.adapter.adapt(item));
          });

          resolve(videos);
        })

        .catch((response) => {
          reject(response);
        });
    });
  }

  search(params: any): Promise<Video[]> {
    return new Promise((resolve, reject) => {

      this.apiService.post('/videos/search', params)

        .then((response) => {

          const videos: Video[] = [];
          response.data.videos.forEach(item => {
            videos.push(this.adapter.adapt(item));
          });

          resolve(videos);
        })

        .catch((response) => {
          reject(response);
        });
    });
  }

  updateWatchedTime(id: string) {
    return new Promise((resolve, reject) => {
      if (this.authService.isGuest()) {
        resolve();
      }
      this.apiService.put('/videos/watched_times', {video_id: id})

        .then((response) => {
          resolve();
        })

        .catch((response) => {
          reject(response);
        });
    });
  }

  markAsViewed(id: string) {
    return new Promise((resolve, reject) => {
      if (this.authService.isGuest()) {
        resolve();
      }

      this.apiService.delete('/videos/interrupted', {video_id: id})

        .then((response) => {
          resolve();
        })

        .catch((response) => {
          reject(response);
        });
    });
  }

  setInterruptionPoint(id, currentPercent) {
    return new Promise((resolve, reject) => {
      if (this.authService.isGuest()) {
        resolve();
      }

      this.apiService.post('/videos/interrupted', {video_id: id, interrupted_at: currentPercent})

        .then((response) => {
          resolve();
        })

        .catch((response) => {
          reject(response);
        });
    });
  }
}
