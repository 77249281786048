import {Component, OnInit} from '@angular/core';
import {FormBuilder, FormGroup, Validators} from '@angular/forms';
import {AuthService} from '../../../services/auth/auth.service';
import {ActivatedRoute, Router} from '@angular/router';
import {PasswordValidator} from '../../../../helpers/validators';
import {UserService} from '../../../../model/user/user.service';

@Component({
  selector: 'page-change-profile-password-component',
  templateUrl: './change-profile-password.component.html',
  styleUrls: ['./change-profile-password.component.scss']
})

export class PageChangeProfilePasswordComponent implements OnInit {

  form: FormGroup;
  loading = false;
  submitted = false;
  error: string;

  constructor(
    private formBuilder: FormBuilder,
    private route: ActivatedRoute,
    private authService: AuthService,
    private router: Router,
    private userService: UserService
  ) {
  }

  ngOnInit() {

    this.form = this.formBuilder.group({
        password: ['', [Validators.required]],
        password_confirmation: ['', [Validators.required]]
      },
      {
        validator: PasswordValidator.MatchPassword
      });
  }

  get f() {
    return this.form.controls;
  }

  onSubmit() {

    this.submitted = true;

    if (this.form.invalid) {
      return;
    }

    this.loading = true;

    this.userService.updatePassword(this.f.password.value)
      .then(data => {
        this.router.navigate(['/profile']);
      })
      .catch(data => {
        this.error = data;
        this.loading = false;
      });
  }
}
