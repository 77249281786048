import {Component, Input, OnInit, ViewEncapsulation} from '@angular/core';
import {Playlist} from '../../../../model/playlist/playlist';

@Component({
  selector: 'basic-playlist-carousel-list',
  templateUrl: './playlist-carousel-list.component.html',
  styleUrls: ['./playlist-carousel-list.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class BasicPlaylistCarouselListComponent implements OnInit {

  @Input() playlists: Playlist[];

  constructor() { }

  ngOnInit() {
  }

}
