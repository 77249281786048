import {Component, OnInit} from '@angular/core';
import {PlatformService} from '../../../services/platform/platform.service';

@Component({
  selector: 'basic-splash-screen',
  templateUrl: './splash-screen.component.html',
  styleUrls: ['./splash-screen.component.scss']
})
export class BasicSplashScreenComponent implements OnInit {
  isVisible = true;

  constructor(platformService: PlatformService) {
    this.isVisible = platformService.isDesktop();
  }

  ngOnInit() {
  }

  hide() {
    this.isVisible = false;
  }

  show() {
    this.isVisible = true;
  }

}
