import {Injectable} from '@angular/core';
import {TranslateService} from '@ngx-translate/core';
import {AuthService} from '../auth/auth.service';

@Injectable({
  providedIn: 'root'
})

export class TranslationService {

  private currentLang = null;

  private defaultLang: string = 'ca';

  constructor(
    private translateService: TranslateService,
    private authService: AuthService
  ) {
  }

  public init(language = null) {

    this.currentLang = this.detectLang();
    this.translateService.setDefaultLang(language || this.defaultLang);
    this.translateService.use(language || this.defaultLang);
  }

  private detectLang() {

    let user = this.authService.getUser();

    let lang: string;

    if (user && user.language) {
      lang = user.language;
    } else if (navigator.language) {
      lang = navigator.language.substr(0, 2);
    } else {
      lang = this.defaultLang;
    }

    return lang;
  }

  /**
   * Returns the translation for given key
   * @param key what you want to translate
   * @param params a key-value object to use in the required translation
   * @return Promise
   */
  public async getTranslation(key: string, params = null) {
    return await this.translateService.get(key, params).toPromise();
  }

  public get(key: string) {
    return this.translateService.instant(key);

  }

  public refreshLang(language) {
    this.init(language);
  }
}
