import {Component, OnInit, ViewEncapsulation} from '@angular/core';
import {Video} from '../../../../model/video/video';
import {VideoService} from '../../../../model/video/video.service';
import {UserService} from '../../../../model/user/user.service';

@Component({
  selector: 'page-my-list',
  templateUrl: './my-list.component.html',
  styleUrls: ['./my-list.component.scss'],
  encapsulation: ViewEncapsulation.None
})

export class PageMyListComponent implements OnInit {

  videos: Video[];

  constructor(
    private userService: UserService
  ) {
  }

  ngOnInit() {
    this.fetchVideos();
  }

  fetchVideos() {

    this.userService.getMyList().then(videos => this.videos = videos);
  }
}
