import {Component, ViewEncapsulation} from '@angular/core';
import {HelpersService} from '../../../services/helpers/helpers.service';

@Component({
  selector: 'layout-main',
  templateUrl: './main.component.html',
  styleUrls: ['./main.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class LayoutMainComponent {

  constructor(
    private helpersService: HelpersService
  ) {
  }

  onActivate(event) {
    this.helpersService.scrollToTop();
  }
}
