import {Injectable} from '@angular/core';
import {Adapter} from '../../core/adapter';
import {Video} from './video';
import {TagAdapter} from '../tag/tag.adapter';
import {Tag} from '../tag/tag';
import {Media} from '../media/media';
import {MediaAdapter} from '../media/media.adapter';
import * as moment from 'moment';
import {Moment} from 'moment';
import {VideoAd} from '../video-ad/video-ad';
import {VideoAdAdapter} from '../video-ad/video-ad.adapter';


@Injectable({
  providedIn: 'root'
})

export class VideoAdapter implements Adapter<Video> {

  constructor(
    public videoTagAdapter: TagAdapter,
    public mediaAdapter: MediaAdapter,
    private videoAdAdapter: VideoAdAdapter
  ) {
  }

  adapt(item: any): Video {

    let tags: Tag[] = [];
    if (Array.isArray(item.tags) && item.tags.length) {
      tags = item.tags.map(this.videoTagAdapter.adapt);
    }

    let thumbnail: Media;
    if (item.thumbnail) {
      thumbnail = this.mediaAdapter.adapt(item.thumbnail);
    }

    let createdAt: Moment;
    if (item.created_at) {
      createdAt = moment.unix(item.created_at);
    }

    let updatedAt: Moment;
    if (item.updated_at) {
      updatedAt = moment.unix(item.updated_at);
    }

    let ads: any = {};
    if (item.hasOwnProperty('ads')) {

      if (!item.ads.hasOwnProperty('before')) {
        item.ads.before = [];
      }

      let before: VideoAd[] = [];
      item.ads.before.forEach(itemAd => {
        before.push(this.videoAdAdapter.adapt(itemAd));
      });
      ads.before = before;

      // let during: VideoAd[] = [];
      // item.ads.during.forEach(itemAd => {
      //   during.push(this.videoAdAdapter.adapt(itemAd));
      // });
      // ads.during = during;

      if (!item.ads.hasOwnProperty('after')) {
        item.ads.after = [];
      }

      let after: VideoAd[] = [];
      item.ads.after.forEach(itemAd => {
        after.push(this.videoAdAdapter.adapt(itemAd));
      });
      ads.after = after;
    }

    return new Video(
      item.id,
      item.title,
      item.subtitle,
      tags,
      item.url,
      item.on_live,
      thumbnail,
      ads,
      item.interrupted_at,
      createdAt,
      updatedAt,
    );
  }
}
