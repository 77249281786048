import {Component, OnInit, ViewEncapsulation} from '@angular/core';
import {Router} from '@angular/router';
import {AuthService} from '../../../services/auth/auth.service';
import {UserService} from '../../../../model/user/user.service';
import {Language} from '../../../../model/language/language';
import {LanguageService} from '../../../../model/language/language.service';
import {User} from '../../../../model/user/user';
import {TranslationService} from '../../../services/translation/translation.service';

@Component({
  selector: 'app-change-profile-language',
  templateUrl: './change-profile-language.component.html',
  styleUrls: ['./change-profile-language.component.scss']
})
export class PageChangeProfileLanguageComponent implements OnInit {

  loading = false;
  languages: Language[] = [];
  selectedLanguage: Language = null;
  user: User = null;

  constructor(
    private authService: AuthService,
    private router: Router,
    private languageService: LanguageService,
    private translationService: TranslationService,
    private userService: UserService) {
    this.user = this.authService.getUser();
  }

  ngOnInit() {
    this.languageService.list()
      .then(languages => {
        this.languages = languages;
      });
  }

  setLanguage(language: Language) {
    this.selectedLanguage = language;
    this.loading = true;
    this.userService.updateLanguage(this.selectedLanguage.id)
      .then(data => {
        this.loading = false;
        this.translationService.refreshLang(language);
        this.router.navigate(['/profile']);
      })
      .catch(response => {
        console.log('err update language', response.error.data.error);
      });
  }
}
