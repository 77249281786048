import {Injectable} from '@angular/core';
import {ActivatedRouteSnapshot, CanActivate, Router, RouterStateSnapshot, UrlTree} from '@angular/router';
import {Observable} from 'rxjs';
import {StatusService} from '../../services/status/status.service';

@Injectable({
  providedIn: 'root'
})
export class HomeGuard implements CanActivate {

  constructor(private statusService: StatusService, private router: Router) {
  }

  canActivate(
    next: ActivatedRouteSnapshot,
    state: RouterStateSnapshot): Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree {
    const promise = new Promise((resolve, reject) => {
      this.statusService.checkStatus()
        .then(data => {
          resolve(data.data.status === this.statusService.STATUS_UP);
        });
    });

    return promise.then(up => {
      if (up) {
        this.router.navigate(['/']);
      }

      return !up;
    });
  }
}
