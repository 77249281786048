import {Injectable} from '@angular/core';
import ElementQueries from 'css-element-queries/src/ElementQueries';

@Injectable({
  providedIn: 'root'
})

export class ElementQueriesService {

  cssClassesInitialized: Array<string> = [];

  constructor() {
    ElementQueries.listen();
  }

  /**
   * It's necessary to call init always that a new style tag is added to dom.
   * In Angular, always that a component is added, its scss file is added inline at head
   * and it's necessary that ElementQueries reload these styles
   *
   * @param cssClass
   */
  init(cssClass?: string) {

    if (cssClass && this.cssClassesInitialized.indexOf(cssClass) !== -1) {
      return false;
    }

    if (cssClass) {
      this.cssClassesInitialized.push(cssClass);
    }

    ElementQueries.init();
  }
}
