import {Moment} from 'moment';

export class Media {

  constructor(
    public id: string,
    public fileName: string,
    public fileUrl: string,
    public mimeType: string,
    public size: string,
    public conversions: {small: string, medium: string},
    public customProperties: string,
    public createdAt: Moment,
    public updatedAt: Moment
  ) {
  }
}
