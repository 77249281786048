import {Component, OnInit, ViewEncapsulation} from '@angular/core';
import {FormBuilder, FormGroup, Validators} from '@angular/forms';
import {AuthService} from '../../../services/auth/auth.service';
import {User} from '../../../../model/user/user';
import {UserService} from '../../../../model/user/user.service';
import {Router} from '@angular/router';

@Component({
  selector: 'page-profile',
  templateUrl: './profile.component.html',
  styleUrls: ['./profile.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class PageProfileComponent implements OnInit {

  form: FormGroup;
  loading = false;
  submitted = false;
  error: string;
  user: User;

  constructor(
    private formBuilder: FormBuilder,
    public authService: AuthService,
    private userService: UserService,
    private router: Router
  ) {
  }

  ngOnInit() {
    const user: User = this.authService.getUser();
    this.form = this.formBuilder.group({
      email: [user.email, [Validators.required, Validators.email]],
    });

    this.user = this.authService.getUser();
  }

  get f() {
    return this.form.controls;
  }

  updateEmail() {
    this.submitted = true;

    if (this.form.invalid) {
      return;
    }

    this.loading = true;

    this.userService.updateEmail(this.f.email.value);
  }

  logout() {
    this.authService.logout();
    this.router.navigate(['/login']);
  }
}
