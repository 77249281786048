import {Video} from '../video/video';
import {Moment} from 'moment';

export class Language {

  constructor(
    public id: string,
    public name: string,
    public iso3: string
  ) {
  }
}
