import {Injectable} from '@angular/core';
import {Adapter} from '../../core/adapter';
import {VideoAd} from './video-ad';
import * as moment from 'moment';
import {Moment} from 'moment';


@Injectable({
  providedIn: 'root'
})

export class VideoAdAdapter implements Adapter<VideoAd> {

  constructor() {
  }

  adapt(item: any): VideoAd {

    let createdAt: Moment;
    if (item.created_at) {
      createdAt = moment.unix(item.created_at);
    }

    let updatedAt: Moment;
    if (item.updated_at) {
      updatedAt = moment.unix(item.updated_at);
    }

    return new VideoAd(
      item.id,
      item.url,
      item.skip_at,
      item.when,
      item.starts_after,
      createdAt,
      updatedAt,
    );
  }
}
