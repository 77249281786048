import {Pipe, PipeTransform} from '@angular/core';

@Pipe({
  name: 'minuteSeconds'
})
export class MinuteSecondsPipe implements PipeTransform {

  transform(value: number): string {
    let valueRounded = Math.ceil(value);
    const minutes: number = Math.floor(valueRounded / 60);
    const seconds: number = valueRounded - minutes * 60;
    return String(minutes).padStart(2, '0') + ':' + String(seconds).padStart(2, '0');
  }
}
