import {AfterViewInit, Component, EventEmitter, Input, OnChanges, Output, ViewEncapsulation} from '@angular/core';
import Keyboard from 'simple-keyboard';
import {SpatialNavigationService} from '../../../services/spatial-navigation/spatial-navigation.service';

@Component({
  selector: 'basic-smart-keyboard',
  templateUrl: './smart-keyboard.component.html',
  styleUrls: ['./smart-keyboard.component.scss'],
  encapsulation: ViewEncapsulation.None
})

export class BasicSmartKeyboardComponent implements AfterViewInit, OnChanges {
  @Output() valueChange = new EventEmitter<string>();
  @Input('value') value;
  keyboard: Keyboard;

  constructor(private spatialNavigationService: SpatialNavigationService) {
  }

  ngOnChanges(changes: any) {

    this.value = changes.value.currentValue;

    if (this.keyboard) {
      this.keyboard.setInput(this.value);
    }
  }

  ngAfterViewInit(): void {

    this.keyboard = new Keyboard({
      onChange: input => this.onChange(input),
      onKeyPress: button => this.onKeyPress(button),
      layout: {
        default: [
          '1 2 3 4 5 6 7 8 9 0',
          'q w e r t y u i o p',
          'a s d f g h j k l',
          '{leftShift} z x c v b n m',
          '.com @ {space} {bksp}'
        ],
        shift: [
          '! @ # $ % ^ & * ( )',
          'Q W E R T Y U I O P',
          'A S D F G H J K L',
          '{leftShift} Z X C V B N M',
          '.com @ {space} {bksp}'
        ]
      },
      display: {
        '{tab}': '&#x21B9;', // also &#x21E5;
        '{bksp}': '&#x232B;',
        '{enter}': '&#x23CE;',
        '{leftShift}': '&#x21E7;',
        '{rightShift}': '&#x21E7;',
        '{lock}': '&#x21EA;',
        '{space}': '&#x2423',
      }
    });

    (this.keyboard as any).keyboardDOM.addEventListener('keydown', ev => {
      const key = ev.key || ev.keyIdentifier || ev.keyCode;
      if (key === 'Enter') {
        ev.target.dispatchEvent(new Event('pointerdown'));
      }
    });

    this.spatialNavigationInit();
  }

  onChange(value: string) {

    this.value = value;
    this.valueChange.emit(value);
  }

  onKeyPress(button) {

    if (button === '{leftShift}' || button === '{rightShift}' || button === '{lock}') {
      this.handleShift();
      const $element: HTMLElement = document.querySelector(
        '[data-skbtn="' + button + '"]'
      );
      $element.focus();
    }
  }

  handleShift() {

    const currentLayout = this.keyboard.options.layoutName;
    const shiftToggle = currentLayout === 'default' ? 'shift' : 'default';

    this.keyboard.setOptions({
      layoutName: shiftToggle,
    });

    this.spatialNavigationInit();
  }

  spatialNavigationInit() {

    this.spatialNavigationService.add('.hg-button');
  }
}
