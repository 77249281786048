import {Component, Input, AfterViewInit, ViewChild} from '@angular/core';
import {Category} from '../../../../../../model/category/category';
import {SlickCarouselComponent} from 'ngx-slick-carousel';
import jQuery from 'jquery';

@Component({
  selector: 'basic-category-carousel-smart-tv',
  templateUrl: './category-carousel-smart-tv.component.html',
  styleUrls: ['./category-carousel-smart-tv.component.scss']
})

export class BasicCategoryCarouselSmartTVComponent implements AfterViewInit {
  @ViewChild('slickCarousel') slickCarousel: SlickCarouselComponent;
  @Input() category: Category;

  slidesToShow = 4;

  slideConfig = {
    slidesToShow: this.slidesToShow,
    slidesToScroll: 1,
    arrows: false,
    infinite: false,
    swipe: false,
    accessibility: false,
    speed: 100 // ms
  };

  showAllHidden: boolean = false;

  slickCarouselNative: Node;

  slickBeforeChange(e) {
    this.showAllHidden = e.nextSlide != 0;
  }

  ngAfterViewInit() {
    this.slickCarouselNative = (<any> this.slickCarousel).el.nativeElement;
  }

  onKeyDown(event) {

    // Left

    if (event.keyCode == 37) {

      setTimeout(() => {
        if (!this.getFocusedItem().hasClass('slick-active')) {
          this.slickCarousel.slickGoTo(this.getFocusedItemIndex());
        }
      });
    }

    // Right

    if (event.keyCode == 39) {

      setTimeout(() => {
        // if (this.getFocusedItemIndex() < this.category.videos.length - this.slidesToShow + 1) {
          this.slickCarousel.slickGoTo(this.getFocusedItemIndex());
        // }
      });
    }
  }

  getFocusedItem() {
    const focusedButton = jQuery(this.slickCarouselNative).find('.Button:focus');
    return jQuery(focusedButton).closest('[data-slick-index]');
  }

  getFocusedItemIndex() {
    return this.getFocusedItem().data('slick-index')
  }
}

