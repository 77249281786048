import {Injectable} from '@angular/core';
import * as SpatialNavigation from 'spatial-navigation-js';

@Injectable({
  providedIn: 'root'
})

export class SpatialNavigationService {

  private spatialNavigation: SpatialNavigation;

  private defaultSelector: string = 'a, input, button, select, *[focusable]';

  private readonly config;

  constructor() {

    this.spatialNavigation = SpatialNavigation;

    this.config = {
      straightOnly: false,
      rememberSource: false
    };
  }

  init() {

    this.spatialNavigation.init(this.config);

    this.add(this.defaultSelector);

    this.focusFirstElement();
  }


  add(selector: any) {

    const config = Object.assign({}, this.config);
    config.selector = selector;

    this.spatialNavigation.add(config);

    this.spatialNavigation.makeFocusable();
  }

  focusFirstElement(selector?: string) {

    let focusableElement: Element = null;

    if (typeof selector !== "undefined") {
      focusableElement = document.querySelector(selector).querySelectorAll(this.defaultSelector)[0];
    }

    this.spatialNavigation.focus(focusableElement);
  }
}
