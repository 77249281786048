import {Injectable} from '@angular/core';
import {ApiService} from '../../app/services/api/api.service';
import {VideoAdapter} from '../video/video.adapter';
import {Video} from '../video/video';
import {AuthService} from '../../app/services/auth/auth.service';
import {UserAdapter} from './user.adapter';

@Injectable({
  providedIn: 'root'
})

export class UserService {

  constructor(
    private apiService: ApiService,
    private authService: AuthService,
    private videoAdapter: VideoAdapter,
    private userAdapter: UserAdapter
  ) {

  }

  getMyList(): Promise<Video[]> {
    return new Promise((resolve, reject) => {

      this.apiService.get('/user/videos/my-list')

        .then((response) => {

          let videos: Video[] = [];
          response.data.forEach(item => {
            videos.push(this.videoAdapter.adapt(item));
          });

          resolve(videos);
        })

        .catch((response) => {
          reject(response);
        });
    });
  }

  getContinueWatching(): Promise<Video[]> {
    return new Promise((resolve, reject) => {

      this.apiService.get('/videos/interrupted')

        .then((response) => {

          let videos: Video[] = [];
          response.data.videos.forEach(item => {
            videos.push(this.videoAdapter.adapt(item));
          });

          resolve(videos);
        })

        .catch((response) => {
          reject(response);
        });
    });
  }

  updateEmail(email: string) {
    return new Promise((resolve, reject) => {
      this.apiService.put('/profile/email', {email})
        .then((response) => {
          resolve();
        })
        .catch(response => {
          reject(response.error.data);
        });
    });
  }

  updateLanguage(language: string) {
    return new Promise((resolve, reject) => {
      if (this.authService.isGuest()) {
        localStorage.setItem('lang', language);
        resolve();
      } else {
        this.apiService.put('/profile/language', {language})
          .then((response) => {
            resolve();
          })
          .catch(response => {
            reject(response.error.data);
          });
      }
    });
  }

  updatePassword(password: string) {
    return new Promise((resolve, reject) => {
      this.apiService.put('/profile/password', {password})
        .then((response) => {
          resolve();
        })
        .catch(response => {
          reject(response.error.data);
        });
    });
  }

  updateImage(image: string) {
    return new Promise((resolve, reject) => {
      this.apiService.put('/profile/image', {image})
        .then((response) => {
          resolve();
        })
        .catch(response => {
          reject(response.error.data);
        });
    });
  }
}
