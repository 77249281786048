import {Injectable} from '@angular/core';
import {ApiService} from '../../app/services/api/api.service';
import {Category} from './category';
import {CategoryAdapter} from './category.adapter';

@Injectable({
  providedIn: 'root'
})

export class CategoryService {

  constructor(
    private apiService: ApiService,
    private categoryAdapter: CategoryAdapter
  ) {

  }

  getRootCategories(): Promise<Category[]> {
    return new Promise((resolve, reject) => {
      this.apiService.get('/categories/root')

        .then((response) => {

          let categories: Category[] = [];
          response.data.categories.forEach(item => {
            categories.push(this.categoryAdapter.adapt(item));
          });

          resolve(categories);
        })

        .catch((response) => {
          reject(response);
        });
    });
  }

  getById(id): Promise<Category> {
    return new Promise((resolve, reject) => {
      this.apiService.get('/categories/' + id)

        .then((response) => {

          let category = this.categoryAdapter.adapt(response.data.category);

          resolve(category);
        })

        .catch((response) => {
          reject(response);
        });
    });
  }

  getBySlug(slug): Promise<Category> {
    return new Promise((resolve, reject) => {
      this.apiService.get('/categories/slug/' + slug)

        .then((response) => {

          let category = this.categoryAdapter.adapt(response.data.category);

          resolve(category);
        })

        .catch((response) => {
          reject(response);
        });
    });
  }
}
