import {EventEmitter, Injectable} from '@angular/core';
import {Location} from '@angular/common';
import {ActivatedRoute, Router} from '@angular/router';

@Injectable({
  providedIn: 'root'
})

export class SearchService {

  onSearch = new EventEmitter<any>();
  backRoute = '';
  query = '';

  constructor(
    public location: Location,
    private route: ActivatedRoute,
    private router: Router
  ) {

    this.query = this.route.snapshot.params.q;

    this.onSearch.subscribe((query: string) => {

      if (!this.query) {
        this.backRoute = this.location.path();
      }

      this.query = query;
      this.navigate();
    });
  }

  public getQuery() {
    return this.query;
  }

  navigate() {

    if (this.query) {
      this.updateSearchUrl();
    } else {
      this.goBack();
    }
  }

  updateSearchUrl() {
    const endURL = '/search?q=' + this.query;
    this.router.navigateByUrl(endURL);
  }

  goBack() {
    this.router.navigateByUrl(this.backRoute);
  }
}
