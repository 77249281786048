import {Injectable} from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class WebStorageService {

  constructor() {
  }

  set(key: string, val: any) {
    localStorage.setItem(key, val);
  }

  get(key): string {
    return localStorage.getItem(key);
  }

  has(key): boolean {
    return localStorage.getItem(key) !== null;
  }

  remove(key: string) {
    localStorage.removeItem(key);
  }
}
