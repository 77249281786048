import {Injectable} from '@angular/core';
import {Adapter} from '../../core/adapter';
import {Category} from './category';

@Injectable({
  providedIn: 'root'
})

export class CategoryAdapter implements Adapter<Category> {

  constructor() {
  }

  adapt(item: any): Category {

    let parentCategory: Category;
    if (item.parent_category) {
      parentCategory = this.adapt(item.parent_category);
    }

    let childrenCategories: Category[] = [];
    if (item.children_categories) {
      item.children_categories.forEach((child_category) => {
        childrenCategories.push(this.adapt(child_category));
      });
    }

    return new Category(
      item.id,
      item.name,
      item.slug,
      parentCategory,
      childrenCategories
    );
  }
}
