import {Component, Input, OnInit} from '@angular/core';
import {FormControl} from '@angular/forms';

@Component({
  selector: 'form-control-errors',
  templateUrl: './errors.component.html'
})
export class FormControlErrorsComponent implements OnInit {

  @Input() errors: Object;

  constructor() { }

  ngOnInit() {
  }

}
