import {Injectable} from '@angular/core';
import {HttpErrorResponse, HttpEvent, HttpHandler, HttpHeaders, HttpInterceptor, HttpRequest, HttpResponse} from '@angular/common/http';
import {Observable} from 'rxjs';
import {tap} from 'rxjs/operators';
import environment from '../../../environments/environment';
import {AuthService} from '../auth/auth.service';
import {UserAdapter} from '../../../model/user/user.adapter';

@Injectable({
  providedIn: 'root'
})
export class JwtInterceptorService implements HttpInterceptor {

  private noAuthServices = [
    {
      url: environment.api + '/sessions',
      method: 'POST',
      exact: false
    }
  ];

  constructor(
    private authService: AuthService,
    private userAdapter: UserAdapter
  ) {
  }

  intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {

    const noAuthServices = this.noAuthServices.filter(service => {
      if (service.exact) {
        return request.url === service.url && service.method === request.method;
      }
      return request.url.startsWith(service.url) && service.method === request.method;
    });

    const newUrl = !request.url.startsWith('http') ? '.' + request.url : request.url;
    const headers: { Authorization?: string, 'User-Language'?: string } = {};

    if (this.authService.isGuest()) {
      headers['User-Language'] = localStorage.getItem('lang') ? localStorage.getItem('lang').toString() : '';
    }

    if (noAuthServices.length === 0 && this.authService.getToken()) {
      headers['Authorization'] = 'Bearer ' + this.authService.getToken();
    }

    const dupReq = request.clone({
      headers: new HttpHeaders(headers),
      url: newUrl
    });

    return next.handle(dupReq).pipe(
      tap(event => {

        if (event instanceof HttpResponse) {
          // console.log('OK', event);

          if (event.body && event.body.data && event.body.data[this.authService.getTokenKey()]) {
            this.authService.setToken(event.body.data[this.authService.getTokenKey()]);
          }

          if (event.body && event.body.data && event.body.data['user']) {
            this.authService.setUser(this.userAdapter.adapt(event.body.data.user));
          }
        }
      }, err => {
        if (err instanceof HttpErrorResponse) {
          console.log(err, err.error.data ? err.error.data.message : err.message);

          if (err.status === 403) {
            console.log('error 403');
          }
        }
      })
    );
  }
}
