import {Component, Input, OnInit} from '@angular/core';

@Component({
  selector: 'basic-show-all',
  templateUrl: './show-all.component.html',
  styleUrls: ['./show-all.component.scss']
})
export class BasicShowAllComponent implements OnInit {

  @Input() hidden: boolean;
  @Input() url: string;

  constructor() {
  }

  ngOnInit() {
  }

}
