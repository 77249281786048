import {Injectable} from '@angular/core';
import {Adapter} from '../../core/adapter';
import {Media} from './media';
import * as moment from 'moment';
import {Moment} from 'moment';

@Injectable({
  providedIn: 'root'
})

export class MediaAdapter implements Adapter<Media> {

  adapt(item: any): Media {

    let createdAt: Moment;
    if (item.created_at) {
      createdAt = moment.unix(item.created_at);
    }

    let updatedAt: Moment;
    if (item.updated_at) {
      updatedAt = moment.unix(item.updated_at);
    }

    let conversions: { small: string, medium: string };
    if (item.conversions) {
      conversions = {
        small: item.conversions.small_thumbnail,
        medium: item.conversions.medium_thumbnail,
      };
    }

    return new Media(
      item.id,
      item.file_name,
      item.file_url,
      item.mime_type,
      item.size,
      conversions,
      item.custom_properties,
      createdAt,
      updatedAt
    );
  }
}
