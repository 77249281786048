import {Injectable} from '@angular/core';
import {Adapter} from '../../core/adapter';
import {User} from './user';
import {MediaAdapter} from '../media/media.adapter';

@Injectable({
  providedIn: 'root'
})

export class UserAdapter implements Adapter<User> {

  constructor(
    public mediaAdapter: MediaAdapter
  ) {
  }

  adapt(item: any): User {

    return new User(
      item.id,
      item.email,
      item.name,
      item.surname,
      item.image,
      item.language
    );
  }

  serialize(user: User) {

    return JSON.stringify(user);
  }

  unserialize(userAsString: string) {

    const item = JSON.parse(userAsString);

    return new User(
      item.id,
      item.email,
      item.name,
      item.surname,
      item.image,
      item.language
    );
  }
}
