import {Component, OnInit, ViewEncapsulation} from '@angular/core';
import {Banner} from '../../../../model/banner/banner';
import {BannerService} from '../../../../model/banner/banner.service';
import {Playlist} from '../../../../model/playlist/playlist';
import {VideoService} from '../../../../model/video/video.service';
import {TranslationService} from '../../../services/translation/translation.service';
import {UserService} from '../../../../model/user/user.service';
import {Category} from '../../../../model/category/category';
import {CategoryService} from '../../../../model/category/category.service';
import {AuthService} from '../../../services/auth/auth.service';

@Component({
  selector: 'page-home',
  templateUrl: './home.component.html',
  styleUrls: ['./home.component.scss'],
  encapsulation: ViewEncapsulation.None
})

export class PageHomeComponent implements OnInit {

  banners: Banner[];

  playlists: Playlist[] = [];
  onLivePlaylist: Playlist;
  mostViewedPlaylist: Playlist;
  continueWatchingPlaylist: Playlist;

  constructor(
    private translationService: TranslationService,
    private bannerService: BannerService,
    private videoService: VideoService,
    private userService: UserService,
    private categoryService: CategoryService,
    private authService: AuthService
  ) {
  }

  ngOnInit() {

    this.fetchBanners();

    this.fetchOnLivePlaylist();

    // this.fetchMostViewedPlaylist();

    if (!this.authService.isGuest()) {
      this.fetchContinueWatchingPlaylist();
    }

    this.fetchCategories();
  }

  fetchBanners() {

    this.bannerService.list().then(banners => this.banners = banners);
  }

  fetchOnLivePlaylist() {

    this.onLivePlaylist = new Playlist(
      this.translationService.get('general.on_live'),
      [],
      '/on-live'
    );

    this.videoService.getList({onLive: true}).then(videos => {
      if (videos.length > 0) {
        this.playlists.push(this.onLivePlaylist);
        this.onLivePlaylist.videos = videos;
      }
    });
  }

  fetchMostViewedPlaylist() {

    this.videoService.getList(
      {
        order: 'views',
        limit: 30,
        last: 'month'
      }
    ).then(videos => {
      this.mostViewedPlaylist = new Playlist(
        this.translationService.get('general.most_viewed'),
        videos,
        null
      );
      this.playlists.push(this.mostViewedPlaylist);
    });
  }

  fetchContinueWatchingPlaylist() {

    this.userService.getContinueWatching().then(videos => {
      this.continueWatchingPlaylist = new Playlist(
        this.translationService.get('general.continue_watching'),
        videos,
        null,
        true
      );
      this.playlists.push(this.continueWatchingPlaylist);
    }).catch(err => console.log(err));
  }

  fetchCategories() {

    this.categoryService.getRootCategories().then((categories: any) => {
      categories.forEach((category: Category) => {

        let playlist = new Playlist(
          category.name,
          [],
          '/category/' + category.slug
        );

        this.playlists.push(playlist);

        // const fakeParams = {
        //   limit: 30,
        //   order: 'views',
        //   last: 'month'
        // };

        const params = {
          categoryId: category.id,
          includeChildren: true,
          limit: 30
        };

        this.videoService.getList(params).then(videos => {
          playlist.videos = videos;
        });
      });
    });
  }
}
