import {Injectable} from '@angular/core';
import {Adapter} from '../../core/adapter';
import {Banner} from './banner';
import {Video} from '../video/video';
import {VideoAdapter} from '../video/video.adapter';
import {Media} from '../media/media';
import {MediaAdapter} from '../media/media.adapter';
import * as moment from 'moment';
import {Moment} from 'moment';

@Injectable({
  providedIn: 'root'
})

export class BannerAdapter implements Adapter<Banner> {

  constructor(
    public videoAdapter: VideoAdapter,
    public mediaAdapter: MediaAdapter
  ) {

  }

  adapt(item: any): Banner {

    let video: Video;
    if (item.video) {
      video = this.videoAdapter.adapt(item.video);
    }

    let posterMobile: Media;
    if (item.poster.mobile) {
      posterMobile = this.mediaAdapter.adapt(item.poster.mobile);
    }

    let posterDesktop: Media;
    if (item.poster.desktop) {
      posterDesktop = this.mediaAdapter.adapt(item.poster.desktop);
    }

    let teamLogoHome: Media;
    if (item.teams_logos && item.teams_logos.home) {
      teamLogoHome = this.mediaAdapter.adapt(item.teams_logos.home);
    }

    let teamLogoVisitor: Media;
    if (item.teams_logos && item.teams_logos.visitor) {
      teamLogoVisitor = this.mediaAdapter.adapt(item.teams_logos.visitor);
    }

    let createdAt: Moment;
    if (item.created_at) {
      createdAt = moment.unix(item.created_at);
    }

    let updatedAt: Moment;
    if (item.updated_at) {
      updatedAt = moment.unix(item.updated_at);
    }

    return new Banner(
      item.id,
      item.title,
      item.description,
      video,
      {
        mobile: posterMobile,
        desktop: posterDesktop
      },
      {
        home: teamLogoHome,
        visitor: teamLogoVisitor
      },
      createdAt,
      updatedAt,
    );
  }
}
