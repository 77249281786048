import {Component, OnInit, ViewEncapsulation} from '@angular/core';
import {Video} from '../../../../model/video/video';
import {VideoService} from '../../../../model/video/video.service';

@Component({
  selector: 'page-on-live',
  templateUrl: './on-live.component.html',
  encapsulation: ViewEncapsulation.None
})

export class PageOnLiveComponent implements OnInit {

  videos: Video[];

  constructor(
    private videoService: VideoService
  ) {
  }

  ngOnInit() {
    this.fetchVideos();
  }

  fetchVideos() {

    this.videoService.getList(
      {
        order: 'views',
        last: 'year',
        onLive: true
      }
    ).then(videos => this.videos = videos);
  }
}
