import {Injectable} from '@angular/core';
import {JwtHelperService} from '@auth0/angular-jwt';
import {ApiService} from '../api/api.service';
import {User} from '../../../model/user/user';
import {UserAdapter} from '../../../model/user/user.adapter';
import {WebStorageService} from '../web-storage/web-storage.service';

@Injectable({
  providedIn: 'root'
})

export class AuthService {

  static TOKEN_KEY = 'auth_token';
  static GUEST_EMAIL = 'guest@fcf.com';
  static GUEST_PASSWORD = '123456Bake250';

  private user: User;

  private jwtHelper: JwtHelperService;

  constructor(
    private apiService: ApiService,
    public userAdapter: UserAdapter,
    private webStorageService: WebStorageService
  ) {
    this.jwtHelper = new JwtHelperService();
  }

  getTokenKey() {
    return AuthService.TOKEN_KEY;
  }

  isGuest() {
    return this.getUser() && this.getUser().email === AuthService.GUEST_EMAIL;
  }

  guestLogin(): Promise<any> {
    const data = {
      email: AuthService.GUEST_EMAIL,
      password: AuthService.GUEST_PASSWORD,
    };
    return this.login(data);
  }

  login(data): Promise<any> {
    return new Promise((resolve, reject) => {
      this.apiService.post('/sessions', data)
        .then((response: { data: { auth_token: string, refresh_token: string, user: object } }) => {
          this.setUser(this.userAdapter.adapt(response.data.user));
          resolve(response.data);
        })
        .catch(response => {
          reject(response.error.data);
        });
    });
  }

  registration(data): Promise<any> {
    return new Promise((resolve, reject) => {
      this.apiService.post('/sessions/registration', data)
        .then((response: { data: { auth_token: string, refresh_token: string, user: object } }) => {
          this.setUser(this.userAdapter.adapt(response.data.user));
          resolve(response.data);
        })
        .catch(response => {
          reject(response.error.data);
        });
    });
  }

  logout(): void {
    this.destroyAuthData();
  }

  getToken(): string {
    return this.webStorageService.get(this.getTokenKey()) ? this.webStorageService.get(this.getTokenKey()).toString() : null;
  }

  setToken(token: string) {
    if (!token) {
      this.webStorageService.remove(this.getTokenKey());
    } else {
      this.webStorageService.set(this.getTokenKey(), token);
    }
  }

  getUser(): User {

    if (!this.isValidToken()) {
      return null;
    }

    if (!this.user && this.webStorageService.has('user')) {
      const userFromSession = this.webStorageService.get('user');
      this.user = this.userAdapter.unserialize(userFromSession);
    }

    return this.user;
  }

  setUser(user: User) {

    this.user = user;
    this.webStorageService.set('user', this.userAdapter.serialize(user));
  }

  private destroyUser() {

    this.user = null;
    this.webStorageService.remove('user');
  }

  private isValidToken() {

    const token = this.getToken();

    if (!this.jwtHelper.isTokenExpired(token)) {
      return true;
    }

    this.destroyAuthData();

    return false;
  }

  isAuthenticated(): boolean {
    return this.isValidToken();
  }

  private destroyAuthData() {
    this.setToken(null);
    this.destroyUser();
  }
}
