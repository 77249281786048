import {Injectable} from '@angular/core';
import {Location} from '@angular/common';
import {PlatformService} from '../platform/platform.service';
import {fromEvent} from 'rxjs';
import {Router} from '@angular/router';


@Injectable({
  providedIn: 'root'
})
export class BackButton {

  constructor(
    private location: Location,
    private platformService: PlatformService,
    private router: Router
  ) {

    if (this.platformService.isSmartTV()) {
      fromEvent(document, 'keydown').subscribe((event: KeyboardEvent) => {
        if (event.keyCode === 461) {
          // LG back button
          if (this.router.url === '/') {
            // @ts-ignore
            window.webOS.platformBack();
          } else {
            this.location.back();
          }
        } else if (event.keyCode === 10009) {
          // Samsung back button
          if (this.router.url === '/') {
            // @ts-ignore
            window.tizen.application.getCurrentApplication().exit();
          } else {
            this.location.back();
          }
        }
      });
    }
  }
}
