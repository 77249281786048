import {Component, OnInit, ViewEncapsulation} from '@angular/core';
import {FormBuilder, FormGroup, Validators} from '@angular/forms';
import {ActivatedRoute, Router} from '@angular/router';
import {ApiService} from '../../../services/api/api.service';

@Component({
  selector: 'page-recovery-password',
  templateUrl: './recovery-password.component.html',
  styleUrls: ['./recovery-password.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class PageRecoveryPasswordComponent implements OnInit {

  form: FormGroup;
  loading = false;
  submitted = false;
  error: string;

  constructor(
    private formBuilder: FormBuilder,
    private route: ActivatedRoute,
    private apiService: ApiService,
    private router: Router
  ) {

  }

  ngOnInit() {

    this.form = this.formBuilder.group({
      email: ['', [Validators.required, Validators.email]]
    });
  }

  get f() {
    return this.form.controls;
  }

  onSubmit() {

    this.submitted = true;

    if (this.form.invalid) {
      return;
    }

    this.loading = true;

    this.apiService.post('/passwords/reset_token',
      {
        email: this.f.email.value
      })
      .then(data => {
        this.router.navigate(['/reset-password'],
          {queryParams: {email: this.f.email.value}}
        );
      })
      .catch(response => {
        this.error = response.error.data.error;
        this.loading = false;
      });
  }
}
