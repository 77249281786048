import {Component, Input, OnInit, ViewChild, ViewEncapsulation} from '@angular/core';
import {SlickCarouselComponent} from 'ngx-slick-carousel';
import {Category} from '../../../../../../model/category/category';

@Component({
  selector: 'basic-category-carousel-desktop',
  templateUrl: './category-carousel-desktop.component.html',
  styleUrls: ['./category-carousel-desktop.component.scss']
})
export class BasicCategoryCarouselDesktopComponent implements OnInit {
  @ViewChild('slickCarousel') slickCarousel: SlickCarouselComponent;
  @Input() category: Category;

  slideConfig = {
    slidesToShow: 4,
    slidesToScroll: 4,
    arrows: true,
    infinite: false,
    swipe: false,
    prevArrow: '<div class="CategoryCarousel-arrow CategoryCarousel-arrow--left"><i class="fas fa-chevron-left"></i></div>',
    nextArrow: '<div class="CategoryCarousel-arrow CategoryCarousel-arrow--right"><i class="fas fa-chevron-right"></i></div>',
    responsive: [
      {
        breakpoint: 767,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 2,
          swipe: true,
          arrows: false,
          infinite: true
        }
      }
    ]
  };

  showAllHidden: boolean = false;

  constructor() {
  }

  ngOnInit() {
  }

  slickBeforeChange(e) {
    this.showAllHidden = e.nextSlide != 0;
  }

}
