import {Component, Input, ViewEncapsulation} from '@angular/core';
import {Playlist} from '../../../../model/playlist/playlist';
import {PlatformService} from '../../../services/platform/platform.service';

@Component({
  selector: 'basic-playlist-carousel',
  templateUrl: './playlist-carousel.component.html',
  styleUrls: ['./playlist-carousel.component.scss'],
  encapsulation: ViewEncapsulation.None
})

export class BasicPlaylistCarouselComponent{
  @Input() playlist: Playlist;

  platformService: PlatformService;

  constructor(platformService: PlatformService) {
    this.platformService = platformService;
  }
}
