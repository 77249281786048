import {Component} from '@angular/core';
import {PlatformService} from '../../../services/platform/platform.service';

@Component({
  selector: 'basic-platform-switcher',
  templateUrl: './platform-switcher.component.html',
  styleUrls: ['./platform-switcher.component.scss']
})

export class BasicPlatformSwitcherComponent {

  constructor(public platformService: PlatformService) {

  }
}
