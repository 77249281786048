import {Injectable} from '@angular/core';
import {Adapter} from '../../core/adapter';
import {Tag} from './tag';

@Injectable({
  providedIn: 'root'
})

export class TagAdapter implements Adapter<Tag> {

  adapt(item: any): Tag {
    return new Tag(
      item.id,
      item.name
    );
  }
}
