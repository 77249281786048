import {Component, Input, OnInit, ViewEncapsulation, AfterViewInit, ElementRef} from '@angular/core';
import {HelpersService} from '../../../services/helpers/helpers.service';
import {PlatformService} from '../../../services/platform/platform.service';
import jQuery from 'jquery';
import {AuthService} from '../../../services/auth/auth.service';
import {CategoryService} from '../../../../model/category/category.service';
import {Category} from '../../../../model/category/category';
import {SearchService} from '../../../services/search/search.service';

@Component({
  selector: 'basic-header-mobile',
  templateUrl: './header-mobile.component.html',
  styleUrls: ['./header-mobile.component.scss'],
  encapsulation: ViewEncapsulation.None
})

export class BasicHeaderMobileComponent implements OnInit, AfterViewInit {

  @Input('show-nav-bar') showNavBar = true;

  menuIsActive = false;
  menuIsSearching = false;

  categories: Category[];

  constructor(
    private helpersService: HelpersService,
    public platformService: PlatformService,
    private element: ElementRef,
    private authService: AuthService,
    private categoryService: CategoryService,
    private searchService: SearchService
  ) {
    this.searchService.onSearch.subscribe((query: string) => {

      this.menuIsSearching = true;
      this.handleMainLayoutBlurring(false);

      // Recover the menu when search box is empty looks like a good idea, but can be annoying if the search input is hidded by the mobile keyboard
      /*if (query.length > 0) {
        this.menuIsSearching = true;
        this.handleMainLayoutBlurring(false);
      } else {
        this.menuIsSearching = false;
        this.handleMainLayoutBlurring(true);
      }*/

    });
  }

  ngOnInit() {

    this.fetchCategories();
  }

  getUser() {

    return this.authService.getUser();
  }

  ngAfterViewInit() {

    jQuery(this.element.nativeElement).on('focus', '*', () => {
      this.helpersService.scrollToTop();
    });
  }

  fetchCategories() {

    this.categoryService.getRootCategories().then(categories => this.categories = categories);
  }

  toggleMenu() {
    this.menuIsActive = !this.menuIsActive;
    if (!this.menuIsActive) {
      this.menuIsSearching = false;
    }
    this.handleMainLayoutBlurring(this.menuIsActive);
  }

  handleMainLayoutBlurring(state) {
    jQuery('.MainLayout-main').toggleClass('MainLayout-main--blurred', state);
  }

}
